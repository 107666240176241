import clsx from "clsx";
import { useCallback, useState } from "react";
import { ClarityDocsApi } from "src/api";
import { Form, Popup, PopupProps, TextField } from "src/components/ui"
import { getPayload } from "src/utils/objectUtils";
import { ConfirmPopup } from "../ConfirmPopup";

const session = new ClarityDocsApi();

interface CreateTeamPopupProps extends Omit<PopupProps, 'children'> {
  persistentMode?: boolean;
}

export const CreateTeamPopup = (props: CreateTeamPopupProps) => {
  const [isPending, setIsPending] = useState(false);
  const confirmUserDeleteState = useState(false);

  const createTeam: React.FormEventHandler = useCallback((event) => {
    const payload = getPayload<{ name: string }>(event.currentTarget as HTMLFormElement);

    if (!payload.name) return;

    setIsPending(true);
    session.team.createTeam(payload.name)
      .then(() => {
        props.statePair[1](false);
      })
      .finally(() => setIsPending(false))
  }, [props.statePair]);

  const deleteAccount = async () => {
    setIsPending(true);
    await session.deleteUser();
  }

  return <Popup
    title="Create new team"
    width={ 510 }
    { ...props }
  >
    <Form 
      onSubmit={ createTeam }
      className="flex flex--col"
    >
      <TextField
        name="name"
        label="Name"
        placeholder="Enter team name"
      />

      <div className="flex flex--jbetween">
        <div>
          { props.persistentMode && <button
            className="button button--gray"
            children="Delete account"
            onClick={ () => confirmUserDeleteState[1](true) }
            type="button"
          /> }
        </div>
        <button
          className={clsx({
            "button button--primary": true,
            "button--pending": isPending,
          })}
          children="Create"
          type="submit"
        />
      </div>
    </Form>

    <ConfirmPopup
      title="Delete account"
      message="Are you sure you want to delete your account?"
      statePair={ confirmUserDeleteState }
      onConfirm={ deleteAccount }
      confirmText="Delete account"
      isPending={ isPending }
      danger
    />
  </Popup>
}
