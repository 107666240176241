import { AxiosInstance, AxiosRequestConfig } from "axios";
import { plainToInstance } from "class-transformer";
import type { ClarityDocsApi } from "..";
import { KeepPromise } from "../decorators";
import { DocumentModel } from "../models/document.model";

export enum ClarityDocumentEvent {
  DOCUMENT_CREATED = 'document-created',
  DOCUMENT_DELETED = 'document-deleted',
  DOCUMENT_UPDATED = 'document-updated',
  DOCUMENTS_UPDATED = 'documents-updated',
}

export class DocumentsService {
  private client: AxiosInstance;

  constructor(private api: ClarityDocsApi) {
    this.client = (api as any).client as AxiosInstance;
  }

  /**
   * Fetches documents from a folder
   */
  @KeepPromise()
  public async getList(folderId: string) {
    const folder = this.api.folders.get(folderId);

    if (!folder) {
      return [];
    }

    const { data } = await this.client
      .get<DocumentModel[]>(`/folder/${folderId}/documents`);

    if (!data) {
      return [];
    }

    folder.documents = plainToInstance(DocumentModel, data, { excludeExtraneousValues: true });

    this.api.dispatch(ClarityDocumentEvent.DOCUMENTS_UPDATED, {
      list: folder.documents,
      folder,
    });

    return folder.documents;
  }

  /**
   * Uploads a document to a folder
   */
  public async upload(folderId: string, file: File, progressHandler?: (progress: number) => void) {
    const formData = new FormData();
    formData.append('contents', file);
    formData.append('folderId', folderId);

    const requestConfig: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) => {
        if (!progressHandler) {
          return;
        }

        progressHandler(progressEvent.loaded / (progressEvent?.total || 1));
      },
    };

    const { data } = await this.client
      .post<DocumentModel>(`/document`, formData, requestConfig);

    if (!data || !data.id ) {
      return;
    }

    const doc = plainToInstance(DocumentModel, data, { excludeExtraneousValues: true });
    const folder = this.api.folders.get(folderId);

    if (folder) {
      folder.documents = [...folder.documents, doc];
      this.api.dispatch(ClarityDocumentEvent.DOCUMENTS_UPDATED, { list: folder.documents, folder });
    }

    return doc;
  }

  /**
   * Fetches a document by its ID
   */
  public get(documentId: string) {
    const folder = this.api.folders.list.find(folder => !!folder.documentRecords[documentId]);

    if (!folder) {
      return null;
    }

    return folder.documentRecords[documentId];
  }

  public async delete(documentId: string) {
    const doc = this.get(documentId);

    if (!doc) {
      return;
    }

    await this.client.delete(`/document/${documentId}`);

    const folder = this.api.folders.get(doc.folderId);

    if (folder) {
      folder.documents = folder.documents.filter(d => d.id !== documentId);
      this.api.dispatch(ClarityDocumentEvent.DOCUMENTS_UPDATED, { list: folder.documents, folder });
    }
  }

  /**
   * Pins or unpins a document by its ID
   */
  public async pin(documentId: string) {
    const doc = this.get(documentId);

    if (!doc) {
      return;
    }

    doc.isPinned = !doc.isPinned;
    this.api.dispatch(ClarityDocumentEvent.DOCUMENT_UPDATED, doc);
  }

  /**
   * Unpins all documents in a folder
   */
  public async unpinAll(folderId: string) {
    const folder = this.api.folders.get(folderId);

    if (!folder) {
      return;
    }

    const [doc] = folder.pinnedDocuments;

    folder.pinnedDocuments.forEach(doc => {
      doc.isPinned = false;
    });

    this.api.dispatch(ClarityDocumentEvent.DOCUMENT_UPDATED, doc);
  }
}
