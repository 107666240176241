import { Expose, Type } from "class-transformer";
import { ImageModel } from "./image.model";

export enum UserStatus {
  ACTIVATED = '874a917c-9868-4f38-b9b8-ddbec862ffb8',
  DEACTIVATED = '9c0288dc-b232-4c8b-aa28-d7368f920038',
  SUSPENDED = 'e1bacf5d-fd3b-4638-941c-13d0da8561c4',
}

export class UserModel {
  @Expose()
  public id: string;

  @Expose()
  public firstName: string;

  @Expose()
  public lastName: string;

  @Expose()
  public email: string;

  @Expose()
  public bio: string;

  @Expose()
  @Type(() => ImageModel)
  public image?: ImageModel;

  @Expose()
  public isEmailConfirmed: boolean;

  @Expose()
  public tile: string;

  public get initials() {
    return `${this.firstName[0]}${this.lastName[0]}`.toUpperCase();
  }

  public get name() {
    return `${this.firstName} ${this.lastName}`;
  }

  public clone(newData: Partial<UserModel> = {}) {
    return Object.assign(new UserModel(), this, newData);
  }
}

export type UserUpdateModel = Pick<UserModel, 'firstName' | 'lastName' | 'email' | 'bio'>;

export type PasswordUpdateModel = {
  password: string;
  confirmNewPassword?: string;
};
