import { useCallback, useState } from "react";
import { useMembership, useUser } from "src/api/hooks";
import { useTeam } from "src/api/hooks/useTeam";
import { ConfirmPopup } from "src/components/shared/ConfirmPopup";
import { CreateTeamPopup } from "src/components/shared/CreateTeamPopup";
import { ProfileSettingsPopup } from "src/components/shared/ProfileSettingsPopup";
import { TeamSettingsPopup } from "src/components/shared/TeamSettingsPopup";
import { Avatar, Dropdown, DropdownButton, DropdownGroup, DropdownHeader } from "src/components/ui"

export const ProfileMenu = () => {
  const { memberships, membership, notAllowedFor } = useMembership();

  const { user, session } = useUser();
  const { team, teams } = useTeam();
  const [isPending, setIsPending] = useState(false);

  const profileSettingsPopupState = useState(false);
  const teamSettingsPopupState = useState<any>(false);
  const createTeamPopupState = useState(false);
  const confirmLeaveTeamState = useState(false);

  const leaveTeam = useCallback(() => {
    if (!team) return;

    setIsPending(true);
    session.team.leaveTeam(team.id)
      .finally(() => {
        setIsPending(false);
        confirmLeaveTeamState[1](false);
      });
  }, [session, team, confirmLeaveTeamState]);

  const openProfileSettings = useCallback(() => {
    profileSettingsPopupState[1](true)
  }, [profileSettingsPopupState]);

  const openTeamSettings = useCallback(() => {
    teamSettingsPopupState[1](true)
  }, [teamSettingsPopupState]);

  const openMembersSettings = useCallback(() => {
    teamSettingsPopupState[1]('members');
  }, [teamSettingsPopupState]);

  const teamsDropdown = teams.map(t => {
    const tMembership = memberships.find(m => m.teamId === t.id);

    if (tMembership === membership) {
      return null;
    }

    const icon = <Avatar
      src={ t.logo?.url }
      size={ 24 }
      entityName={ t.name }
    />

    return <DropdownButton
      key={ t.id }
      icon={ icon }
      onClick={ () => session.team.setMembership(tMembership!.id) }
    >
      { t.name }
      <p className="txc-label tx-small">
        { session.team.getRoleLabel(tMembership?.role!) }
      </p>
    </DropdownButton>
  });

  const currentTeamIcon = <Avatar
    entityName={ team?.name }
    src={ team?.logo?.url }
    size={ 24 }
  />

  return <>
    <Dropdown
      activator={<button>
        <Avatar
          src={ user?.image?.url }
          entityName={ user?.name }
        />
      </button>}

      vside="top"
      hside="right"
      width={ 260 }
    >
      <DropdownHeader>
        { user?.name }
        <div className="tx-small txc-label">
          { user?.email }
        </div>
      </DropdownHeader>

      <DropdownButton
        icon="edit"
        onClick={ openProfileSettings }
        label="Edit profile"
      />

      <DropdownButton
        icon={ currentTeamIcon }
        submenu={ teamsDropdown.length > 1 ? teamsDropdown : undefined }
      >
        { team?.name }
        <p className="tx-small txc-label">
          { session.team.getRoleLabel(membership?.role!) }
        </p>
      </DropdownButton>

      <DropdownGroup>
        { 
          notAllowedFor(session.team.roles.USER)
          && <DropdownButton 
            icon="settings"
            onClick={ openTeamSettings }
            children="Team settings"
          />
        }

        { notAllowedFor(session.team.roles.USER)
          && <DropdownButton
            icon="team"
            label="Manage members" 
            onClick={ openMembersSettings }
        /> }
        
        <DropdownButton
          icon="leave-team"
          label="Leave team" 
          onClick={ () => confirmLeaveTeamState[1](true) }
        />
      </DropdownGroup>
      
      <DropdownButton 
        icon="add"
        label="Create team" 
        onClick={ () => createTeamPopupState[1](true) }
      />

      <DropdownButton
        icon="logout"
        onClick={ () => session.logout() }
        label="Sign out"
      />
    </Dropdown>

    <ProfileSettingsPopup statePair={ profileSettingsPopupState } />
    <TeamSettingsPopup statePair={ teamSettingsPopupState } />
    <CreateTeamPopup statePair={ createTeamPopupState } />
    <ConfirmPopup
      title="Leave team"
      message="Are you sure you want to leave the team?"
      statePair={ confirmLeaveTeamState }
      onConfirm={ leaveTeam }
      isPending={ isPending }
      confirmText="Leave"
      danger
    />
  </>
}
