import clsx from "clsx";
import { Popup, PopupProps } from "src/components/ui";

interface ConfirmPopupProps extends Omit<PopupProps, 'width' | 'children'> {
  onConfirm: () => void;
  onCancel?: () => void;

  isPending?: boolean;
  message: string;

  confirmText?: string;
  cancelText?: string;
  danger?: boolean;
}

export const ConfirmPopup = (props: ConfirmPopupProps) => {
  const confirmText = props.confirmText || 'Okay';
  const cancelText = props.cancelText || 'Cancel';

  return <Popup
    { ...props }
    width={488}
  >
    <div className="flex flex--col">
      {
        props.message && <p>{ props.message }</p>
      }

      <div className="flex flex--jend">
        <button
          className="button button--gray"
          onClick={() => {
            props.statePair[1](false);
            props.onCancel?.();
          }}
        >
          { cancelText }
        </button>
        <button
          className={clsx({
            "button": true,
            "button--primary": !props.danger,
            "button--danger": props.danger,
            "button--pending": props.isPending,
          })}
          onClick={ () => props.onConfirm() }
        >
          { confirmText }
        </button>
      </div>
    </div>
  </Popup>
}
