import './index.scss';

import { useCallback, useEffect, useRef, useState } from 'react';

import { Message } from 'src/components/shared/Message';
import { useChat, useUser } from 'src/api/hooks';
import { OverlayScrollbarsComponent, OverlayScrollbarsComponentRef } from 'overlayscrollbars-react';
import { ChatForm } from './ChatForm';

const LAZY_LOADING_BREAKPOINT = 1000;

interface ChatProps {
  documentId: string;
  folderId: string;
  screenshot: string | null;
  onResetScreenshot: () => void;
}

export const Chat = (props: ChatProps) => {
  const { user } = useUser();

  const [isInStart, setIsInStart] = useState(true);
  const scrollRef = useRef<OverlayScrollbarsComponentRef | null>(null);

  const { 
    messages, 
    loadMoreMessages,
  } = useChat(props.folderId!);

  const handleScroll = useCallback((_: any, event: Event) => {
    const { target } = event as unknown as { target: HTMLDivElement };
    const isOnBottom = (target.scrollHeight - target.scrollTop - target.clientHeight) < 5;

    setIsInStart(isOnBottom);

    if (target.scrollTop <= LAZY_LOADING_BREAKPOINT) {
      loadMoreMessages();
    }
  }, [loadMoreMessages]);

  const messageEls = user ? messages.map((message, i) => {
    return <Message key={ message.id + i } { ...message } />;
  }) : null;

  // NOTE: Scroll to the bottom when the user is at the start of the chat
  useEffect(() => {
    const scrollEl = scrollRef.current?.getElement()?.children[1] as HTMLDivElement;

    if (scrollEl && isInStart) {
      scrollEl.scrollTop = scrollEl.scrollHeight;
    }

    // NOTE: Prevent stick to top when new messages loaded
    if (scrollEl.scrollTop < 1) {
      scrollEl.scrollTop = 1;
    }
  }, [messages, isInStart]);

  // NOTE: Scroll to the bottom when the chat is loaded
  useEffect(() => {
    const scrollEl = scrollRef.current?.getElement()?.children[1] as HTMLDivElement;

    if (scrollEl) {
      scrollEl.scrollTop = scrollEl.scrollHeight;
    }
  }, []);

  return <div className="chat">
    <div className="chat__messages">
      <OverlayScrollbarsComponent
        className="chat__messages-scroll-wrapper"
        options={{ scrollbars: { autoHide: 'move' } }}
        events={{ scroll: handleScroll }}
        ref={ scrollRef }
      >
        <div className="chat__reverse-container">
          { messageEls }
        </div>
      </OverlayScrollbarsComponent>
    </div>

    <ChatForm { ...props } scrollRef={ scrollRef } />
  </div>;
}
