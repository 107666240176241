import clsx from "clsx";
import { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom";

import { ClarityDocsApi } from "src/api";
import { PasswordResetValidationModel, ResetTokenStatus } from "src/api/models/passwordResetValidation.model";
import { Form, TextField } from "src/components/ui";
import { getPayload } from "src/utils/objectUtils";

import ERROR_IMAGE from "src/assets/images/close-circle.svg";
import SUCCESS_IMAGE from "src/assets/images/tick-circle.svg";
import { PasswordUpdateModel } from "src/api/models/member.model";
import { rules } from "src/utils/validation";

const session = new ClarityDocsApi();

const ERROR_MESSAGE = <div className="flex flex--col flex--gap30 tx-center">
  <div className="tx-center">
    <img src={ ERROR_IMAGE } alt="Error" width="100" />
  </div>

  <h1 className="tx-section-heading">
    Invalid password reset link
  </h1>

  <p className="txc-placeholder tx-center">
    Be sure you entered this page by clicking the link sent to your email
  </p>
  
  <div>
    <Link
      to="/reset-password"
      className="button button--gray" 
      children="Try again"
    />
  </div>
</div>

const UPDATED_MESSAGE = <div className="flex flex--col flex--gap30 tx-center">
  <div>
    <img src={ SUCCESS_IMAGE } alt="Error" width="100" />
  </div>

  <h1 className="tx-section-heading">
    Password updated
  </h1>

  <p className="txc-placeholder tx-center">
    Your password has been changed successfully, now you can log in to the application
  </p>
  
  <div>
    <Link
      to="/signin"
      className="button button--primary" 
      children="Go to login"
    />
  </div>
</div>

const ResetForm = () => {
  const params = useParams<{ code: string }>();
  const [isPending, setIsPending] = useState(false);
  const [isSent, setIsSent] = useState(false);

  const onSubmit: React.FormEventHandler = (e) => {
    e.preventDefault();
    const payload = getPayload<PasswordUpdateModel>(e.currentTarget as HTMLFormElement);

    setIsPending(true);
    session.updatePassword(payload, params.code)
      .finally(() => setIsPending(false))
      .finally(() => setIsSent(true));
  };

  if (isSent) {
    return UPDATED_MESSAGE;
  }

  return <>
    <h1 className="tx-center tx-section-heading">
      Reset password 
    </h1>

    <p className="tx-center txc-placeholder">
      Please enter a new password that is different from the previous one
    </p>

    <Form
      className="flex flex--col flex--gap30" 
      onSubmit={ onSubmit }
    >
      <TextField
        name="password" 
        label="New password" 
        placeholder="Enter new password"
        type="password"
        rules={[
          rules.required(),
        ]}
      />

      <TextField
        name="confirmNewPassword" 
        label="Confirm password" 
        placeholder="Confirm password"
        type="password"
        rules={[
          rules.required(),
          (v, form) => (v === form?.password ? undefined : "Passwords do not match"),
        ]}
      />

      <button
        type="submit"
        className={clsx({
          "button button--primary button--fluid": true,
          "button--pending": isPending,
        })}
      >
        Reset password
      </button>
    </Form>
  </>
}

export const Page = () => {
  const params = useParams<{ code: string }>();
  const [validation, setValidation] = useState<PasswordResetValidationModel | null>(null);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    session.validateResetToken(params.code!)
      .then(setValidation)
      .catch(() => setIsError(true));
  }, [params.code]);

  if (!params.code || isError) {
    return ERROR_MESSAGE;
  }

  if (!validation) {
    return null;
  }

  return <div className="flex flex--col flex--gap30">
    { 
      validation.status !== ResetTokenStatus.VALID
        ? ERROR_MESSAGE
        : <ResetForm />
    }
  </div>
}
