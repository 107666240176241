import { Expose } from "class-transformer";

export class RoleModel {
  @Expose()
  public id: string;

  @Expose()
  public name: string;

  @Expose()
  public synonym: string;
}
