import { Outlet } from 'react-router-dom';
import { LoginGuard } from 'src/guards/login.guard';

export const Name = 'root';

export const Loader = LoginGuard('signin');

export const Page = () => {
  return <Outlet />;
};

