import { Expose, Type } from "class-transformer";
import { Role } from "../services/team.service";
import { UserModel } from "./member.model";
import { TeamModel } from "./team.model";

export class MembershipModel {
  @Expose()
  id: string;

  @Expose()
  teamId: string;

  @Expose()
  userId: string;

  @Expose({ name: 'roleId' })
  role: Role;

  @Expose()
  name: string;

  @Expose()
  description: string;

  @Expose()
  website: string;

  @Expose()
  @Type(() => UserModel)
  user: UserModel

  @Expose()
  canChangeRole: boolean;

  @Expose()
  canDelete: boolean;

  @Expose()
  canEdit: boolean;

  @Expose()
  @Type(() => Date)
  createdAt: Date

  @Expose()
  @Type(() => TeamModel)
  team: TeamModel;
}
