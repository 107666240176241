import { ClarityDocsApi } from "src/api";
import { redirect } from "src/router/routes";
import { getRouteName } from "src/router/routes";

export const LoginGuard = (targetRoute: string) => async () => {
  const session = new ClarityDocsApi();
  const routeName = getRouteName();

  if (await session.isLoggedIn()) {
    return redirect({ name: 'main' });
  }

  if (routeName === targetRoute) {
    return null;
  }

  return redirect({ name: targetRoute });
}

