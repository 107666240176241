import './index.scss';

import { useCallback, useEffect, useState } from "react";
import { FoldersPanel } from "src/components/shared/FoldersPanel"
import { Tooltip, TooltipPosition } from "src/components/ui"

import LOGO_COMPACT from 'src/assets/images/logo-compact.svg';
import { CreateFolderPopup } from "src/components/shared/CreateFolderPopup";
import { ProfileMenu } from './ProfileMenu';
import clsx from 'clsx';

export const WorkspaceLayout = (props: { children: React.ReactNode }) => {
  const createPopupState = useState(false);
  const [isScreenshotMode, setScreenshotMode] = useState(false);

  useEffect(() => {
    const screenshotModeStartHandler = () => setScreenshotMode(true);
    const screenshotModeEndHandler = () => setScreenshotMode(false);

    window.addEventListener('screenshot-mode:start', screenshotModeStartHandler);
    window.addEventListener('screenshot-mode:end', screenshotModeEndHandler);

    return () => {
      window.removeEventListener('screenshot-mode:start', screenshotModeStartHandler);
      window.removeEventListener('screenshot-mode:end', screenshotModeEndHandler);
    }
  }, []);

  const openFolderCreationPopup = useCallback(() => createPopupState[1](true), [createPopupState]);

  return <main className={ clsx({
    "workspace": true,
    "screenshot-mode": isScreenshotMode,
  }) }>
    <aside className="workspace__main-sidebar">
      <img className="workspace__logo" src={ LOGO_COMPACT } alt="Clarity logo" />

      <FoldersPanel />

      <div className="workspace__profile-panel flex flex--col flex--acenter">
        <Tooltip
          content="Create folder"
          position={ TooltipPosition.RIGHT }
        >
          <button
            className="button button--round"
            onClick={ openFolderCreationPopup }
          >
            <i className="icon-plus"></i>
          </button>
        </Tooltip>

        <ProfileMenu />
      </div>
    </aside>

    { props.children }

    <CreateFolderPopup statePair={ createPopupState } />
  </main>
}
