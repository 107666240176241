import { useState } from "react";
import { ClarityDocsApi } from "src/api";
import { PopupProps } from "src/components/ui"
import { ConfirmPopup } from "../../ConfirmPopup";

const session = new ClarityDocsApi();

interface MemberDeleteConfirmPopupProps extends Omit<PopupProps, 'children'> {
  userId: string;
}

export const MemberDeleteConfirmPopup = (props: MemberDeleteConfirmPopupProps) => {
  const [isPending, setIsPending] = useState(false);

  const confirmDelete = () => {
    setIsPending(true);
    session.team.deleteMembership(props.userId)
      .finally(() => {
        setIsPending(false);
        props.statePair[1](false);
      });
  }

  return <ConfirmPopup
    statePair={ props.statePair }
    title="Remove member"
    message="Are you sure you want to remove this member?"
    isPending={ isPending }
    onConfirm={ confirmDelete }
    confirmText="Delete"
    danger
  />
}
