import { Expose, Type } from "class-transformer";
import { ImageModel } from "./image.model";

export enum AssistantStatus {
  ACTIVE = '77210f65-7b9e-4fbf-ba87-aaeb0f1cb7ba',
}

export class AssistantModel {
  @Expose()
  public id: string;

  @Expose()
  public name: string;

  @Expose()
  public description: string;

  @Expose()
  @Type(() => ImageModel)
  public image?: ImageModel

  @Expose()
  public tags?: string[];
}
