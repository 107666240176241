import { Expose, Type } from 'class-transformer';

export enum ResetTokenStatus {
  EXPIRED = 'expired',
  USED = 'used',
  VALID = 'valid',
}

export class PasswordResetValidationModel {
  @Expose()
  public id: string;

  @Expose()
  public userId: string;

  @Expose()
  public email: string;

  @Expose()
  @Type(() => Date)
  public expiresAt: Date;

  @Expose()
  @Type(() => Date)
  public createdAt: Date;

  @Expose()
  public isUsed: boolean;

  public get isExpired() {
    return this.expiresAt < new Date();
  }

  public get status(): ResetTokenStatus {
    if (this.isExpired) {
      return ResetTokenStatus.EXPIRED;
    }

    return this.isUsed
      ? ResetTokenStatus.USED
      : ResetTokenStatus.VALID;
  }
}
