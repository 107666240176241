import './index.scss';
import { createPortal } from "react-dom";
import clsx from 'clsx';

import { useEffect, useRef, useState, cloneElement } from "react";
import { DropdownButton, DropdownButtonProps } from './DropdownButton';
import { DropdownGroup } from './DropdownGroup';
import { Tooltip, TooltipPosition } from '../Tooltip';

export * from "./DropdownHeader"
export * from "./DropdownButton"
export * from "./DropdownGroup";

export interface DropdownProps {
  activator?: React.ReactElement,
  children: React.ReactNode;
  vside?: 'top' | 'bottom';
  hside?: 'left' | 'right';
  alignAxis?: 'x' | 'y';
  width?: number;
  isSubmenu?: boolean;
  dropdownClass?: string;
  tooltip?: string;
  tooltipPosition?: TooltipPosition;
}

const assignButtonProps = (
  child: React.ReactElement | null,
  index: number,
  setIsOpened: (v: boolean) => void
)  => {
  if (!child) return null;

  return cloneElement(child as React.ReactElement, { 
    key: child.type.toString() + index,
    onClick: () => {
      setIsOpened(false);
      (child.props as DropdownButtonProps).onClick?.();
    },
  });
}

export const Dropdown = (props: DropdownProps) => {
  const [isOpened, setIsOpened] = useState(false);
  const activatorRef = useRef<HTMLButtonElement>(null);
  const activator = props.activator 
    ? cloneElement(props.activator, {
      onClick: () => setIsOpened(!isOpened),
      'data-active': !!isOpened,
      ref: activatorRef,
    })
    : null;

  const dropdownRef = useRef<HTMLDivElement>(null);

  let children = Array.isArray(props.children) ? props.children : [props.children];

  const redefineChildren = (child: React.ReactElement, index: number) => {
    if (!child) return child;
    if (child.type === DropdownGroup) {
      const groupChildren = [child.props.children].flat();
      const group = cloneElement(child, {
        key: child.type.toString() + index,
        children: groupChildren.map((c, i) => assignButtonProps(c, i, setIsOpened)),
      });

      return group;
    }
    if (child.type !== DropdownButton) return child;
    return assignButtonProps(child as React.ReactElement, index, setIsOpened);
  }

  children = children.map(redefineChildren);

  // NOTE: This effect is responsible for handling the dropdown positioning
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && activatorRef.current) {
        const requiredClose = !dropdownRef.current.contains(event.target as Node) 
        && !activatorRef.current.contains(event.target as Node);

        if (requiredClose) {
          setIsOpened(false);
        }
      }
    };

    const { current: activatorEl } = activatorRef;
    const { current: dropdownEl } = dropdownRef;

    if (activatorEl && dropdownEl) {
      const { top, left, width } = activatorEl.getBoundingClientRect();
      const computed = window.getComputedStyle(dropdownEl);

      if (props.alignAxis === 'x') {
        dropdownEl.style.left = `${left + width}px`;
        let menuTop = 0;

        switch (props.vside) {
          case 'top':
            menuTop = top - parseInt(computed.paddingTop);
            menuTop -= Math.max(0, menuTop + dropdownEl.offsetHeight - window.innerHeight);
            break;
          case 'bottom':
          default:
            menuTop = top
              + activatorEl.clientHeight
              - dropdownEl.offsetHeight
              + parseInt(computed.paddingBottom);
            break;
        }

        dropdownEl.style.top = `${menuTop}px`;
        return;
      }

      switch (props.vside) {
        case 'top':
          dropdownEl.style.top = `${top - dropdownEl.offsetHeight}px`;
          break;
        case 'bottom':
        default:
          dropdownEl.style.top = `${top + activatorEl.clientHeight}px`;
          break;
      }

      switch (props.hside) {
        case 'left':
          dropdownEl.style.left = `${left + activatorEl.clientWidth - dropdownEl.offsetWidth}px`;
          break;

        case 'right':
        default:
          dropdownEl.style.left = `${left}px`;
          break;
      }
    }

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [isOpened, props.vside, props.hside, props.alignAxis, props.children]);

  const styles = {
    width: (props.width || activatorRef.current?.offsetWidth) + 'px',
  } as React.CSSProperties;

  const menu = isOpened 
    ? createPortal(
      <div 
        className={clsx({
          "dropdown": true,
          "dropdown--submenu": props.isSubmenu,
          [props.dropdownClass || '']: true,
        })}
        ref={ dropdownRef }
        style={ styles }
      >
        <div className="dropdown__window">
          { children }
        </div>
      </div>, document.body)
    : null;

  return <>
    { !props.tooltip
      ? activator 
      : <Tooltip 
        content={ props.tooltip } 
        children={ activator } 
        position={ props.tooltipPosition } 
      />
    }
    { menu }
  </>;
}
