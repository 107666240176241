import { Expose, Type } from "class-transformer";
import { HighlightModel } from "./highlight.model";

export class FootnoteModel {
  @Expose()
  public documentId: string;

  @Expose()
  public documentFilename: string;

  @Expose()
  public pageNumber: number;

  @Expose()
  @Type(() => HighlightModel)
  public highlights: HighlightModel[];
}
