export type RuleFunc = (value: string, form?: Record<string, string>) => string | undefined;
export type RuleDefinition = (...msgs: string[]) => RuleFunc;

export interface Validateable {
  rules?: RuleFunc[];
}

export const rules: Record<string, RuleDefinition> = {
  number: (message = 'Only numbers allowed') => (v) => {
    if (Number.isNaN(+v)) {
      return message;
    }

    return undefined;
  },

  noNumbers: (message = 'Numbers is not allowed') => (v) => {
    if (v.match(/\d/)) {
      return message;
    }

    return undefined;
  },

  password: (
    message = 'Weak password',
    numbersMessage = 'Password must contain numbers',
    specialCharMessage = 'Passwort must contain special chars',
  ) => (v) => {
    if (!v || v.length < 8) {
      return message;
    }

    if (!v.match(/\d/)) {
      return numbersMessage;
    }

    const specialChars = v.match(/[!@#$%^&&*()-=_+]/g);

    if (!specialChars || !specialChars.length) {
      return specialCharMessage;
    }

    return undefined;
  },

  equals: (message, value) => (v) => {
    const targetValue = value;

    if (v !== targetValue) {
      return message;
    }

    return undefined;
  },

  date: (message = 'It\'s not a date') => (v) => {
    const r = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/;

    if (!v || !v.match(r)) {
      return message;
    }

    return undefined;
  },

  required: (message = 'This field is required') => (v) => {
    if (v === '' || v === undefined) {
      return message;
    }

    return undefined;
  },

  email: (message = 'Email is invalid') => (v) => {
    // eslint-disable-next-line
    const r = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

    if (!v || !v.match(r)) {
      return message;
    }

    return undefined;
  },
};
