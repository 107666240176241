import { TextField, TextFieldProps } from "../TextField";

export const SearchBar = (props: TextFieldProps) => {
  return <TextField
    placeholder={ props.placeholder || 'Search' }
    name={ props.name || 'search' }
    appearance="filled"
    appendIcon="search"
    { ...props }
  />
}
