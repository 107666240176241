import clsx from "clsx";
import { useState } from "react";
import { ClarityDocsApi } from "src/api";
import { useMembership } from "src/api/hooks";
import { useTeam } from "src/api/hooks/useTeam";
import { InvitationRequestModel } from "src/api/models/invitationRequest.model";
import { Form, Popup, PopupProps, TextField, Select } from "src/components/ui";
import { getPayload } from "src/utils/objectUtils";
import { rules } from "src/utils/validation";

const session = new ClarityDocsApi();

export const InvitiationPopup = (props: Omit<PopupProps, 'children'>) => {
  const { allowedFor } = useMembership();

  const rolesList = session.team.roleList
    .map(role => ({ 
      value: role.id,
      label: role.name,
      disabled: role.id === session.team.roles.OWNER && !allowedFor(session.team.roles.OWNER),
    }));

  const [isPending, setIsPending] = useState(false);

  const { team } = useTeam();

  if (!team) return null;

  const closePopup = () => props.statePair[1](false);

  const sendInvite: React.FormEventHandler = (e) => {
    const payload = getPayload<InvitationRequestModel>(e.currentTarget as HTMLFormElement);

    setIsPending(true);
    session.team
      .sendInvitation(payload)
      .finally(() => {
        closePopup()
        setIsPending(false);
      });
  }

  return <Popup 
    title = "Invite member"
    statePair={ props.statePair }
    width={ 447 }
  >
    <Form 
      className="flex flex--col"
      onSubmit={ sendInvite }
    >
      <input 
        type="hidden"
        name="teamId"
        value={ team.id }
      />

      <input
        type="hidden"
        name="message"
        value=""
      />

      <input
        type="hidden"
        name="name"
        value=""
      />
      
      <div className="flex">
        <TextField
          name="email"
          label="Email"
          placeholder="Enter email"
          rules={[
            rules.required(),
            rules.email()
          ]}
        />

        <Select
          name="roleId"
          label="Role"
          items={ rolesList }
          defaultValue={ session.team.roles.USER }
          style={{ width: '132px', flexShrink: 0 }}
        />
      </div>

      <div className="flex flex--jend">
        <button 
          className={ clsx({
            "button button--primary": true,
            "button--pending": isPending,
          }) }
          children="Send invitation"
        />
      </div>
    </Form>
  </Popup>
}
