import { AxiosInstance } from "axios";
import type { LoginResultModel } from "../models/loginResult.model";

export abstract class AuthStrategy<T> {
  constructor(protected params: T) {}

  public async authenticate(_client: AxiosInstance): Promise<LoginResultModel> {
    throw new Error("Method not implemented.");
  }
}
