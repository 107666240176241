import { AxiosInstance } from "axios";
import { plainToInstance } from "class-transformer";
import { ClarityDocsApi, ClarityDocsEvent } from "..";
import { KeepPromise } from "../decorators";
import { InvitationModel } from "../models/invitation.model";
import { InvitationRequestModel } from "../models/invitationRequest.model";
import { LogoModel } from "../models/logo.model";
import { UserModel } from "../models/member.model";
import { MembershipModel } from "../models/membership.model";
import { RoleModel } from "../models/role.model";
import { SubscriptionModel } from "../models/subscription.model";
import { SubscriptionPlanModel } from "../models/subscriptionPlan.model";
import { TeamModel, TeamUpdateModel } from "../models/team.model";

export enum ClarityTeamEvent {
  USER_MEMBERSHIP_CHANGED = 'user-membership-changed',
  MEMBERSHIPS_UPDATED = 'memberships-updated',
  TEAMS_UPDATED = 'teams-updated',
  TEAM_UPDATED = 'team-updated',
  TEAM_DELETED = 'team-deleted',
  TEAM_CREATED = 'team-created',
}

interface Roles {
  OWNER: string;
  MANAGER: string;
  USER: string;
}

export type Role = string;

type MemberId = string;

export class TeamService {
  private client: AxiosInstance;
  private membershipRecords: Record<string, MembershipModel> = {};
  private memberships_: MembershipModel[] = [];
  private userRecords: Record<MemberId, UserModel> = {};

  public roleList: RoleModel[] = [];
  public roles: Roles;

  /**
   * Current user's memberships
   */
  public get memberships() {
    return this.memberships_;
  }

  public set memberships(value: MembershipModel[]) {
    this.memberships_ = value;
    this.api.dispatch(ClarityTeamEvent.MEMBERSHIPS_UPDATED, value);
  }

  public teams_: TeamModel[] = [];
  public get teams() {
    return this.teams_;
  }

  public set teams(value: TeamModel[]) {
    this.teams_ = value;
    this.api.dispatch(ClarityTeamEvent.TEAMS_UPDATED, value);
  }

  private currentMembership_: MembershipModel | null = null;

  public get currentMembership() {
    return this.currentMembership_;
  }

  public set currentMembership(value: MembershipModel | null) {
    this.currentMembership_ = value;
    this.api.dispatch(ClarityTeamEvent.USER_MEMBERSHIP_CHANGED, value);
    this.api.saveToLocalstorage('currentMembership', value?.id);
  }

  public get currentTeam() {
    if (!this.currentMembership) {
      return null;
    }

    return this.getTeamCached(this.currentMembership.teamId);
  }

  constructor(private api: ClarityDocsApi) {
    this.client = (api as any).client as AxiosInstance;
    this.roles = new Proxy({
      OWNER: 'Owner',
      USER: 'User',
      MANAGER: 'Manager',
    }, {
      get: (target, prop) => {
        const synonim = target[prop as keyof typeof target];
        const role = this.roleList.find(r => r.synonym === synonim);

        return role?.id || null;
      }
    })
  }

  public async init() {
    return this.loadRoles()
      .then(this.loadMemberships.bind(this));
  }

  private async loadRoles() {
    const { data } = await this.client.get<RoleModel[]>('/roles');
    this.roleList = plainToInstance(RoleModel, data, { excludeExtraneousValues: true });
  }

  private async loadMemberships() {
    const { data } = await this.client.get<MembershipModel[]>('/user/teams');

    if (!data) {
      return;
    }
    
    this.memberships = plainToInstance(MembershipModel, data, { excludeExtraneousValues: true });
    this.teams = this.memberships.map(m => m.team);

    const savedMembership = this.api.getFromLocalStorage('currentMembership');
    this.currentMembership = this.memberships.find(m => m.id === savedMembership) || this.memberships[0];

    this.api.dispatch(ClarityTeamEvent.MEMBERSHIPS_UPDATED, this.memberships);
  }

  public async getMemberships(teamId: string) {
    const team = this.getTeamCached(teamId)!;

    if (!team) return [];

    const { data } = await this.client.get<MembershipModel[]>(`/team/${team.id}/members`);

    team.memberships = plainToInstance(MembershipModel, data, { excludeExtraneousValues: true });
    team.memberships.forEach(m => {
      this.userRecords[m.userId] = m.user;
      this.membershipRecords[m.id] = m;
    });

    this.api.dispatch(ClarityTeamEvent.TEAM_UPDATED, team);

    return team.memberships || [];
  }

  public async updateTeam(id: string, payload: TeamUpdateModel) {
    await this.client.patch<TeamModel>('/team/' + id, payload);

    const team = Object.assign(this.getTeamCached(id)!.clone(), payload);
    this.teams = this.teams.map(t => t.id === id ? team : t);

    this.api.dispatch(ClarityTeamEvent.TEAM_UPDATED, team);
    this.api.dispatch(ClarityDocsEvent.API_MESSAGE, 'Saved!');

    return team;
  }

  public async createTeam(name: string) {
    const { data: membershipRaw } = await this.client.post<MembershipModel>('/team', {
      name,
      website: '',
      description: '',
    });

    const membership = plainToInstance(MembershipModel, membershipRaw, { excludeExtraneousValues: true });

    membership.user = this.api.user!;
    membership.team?.memberships.push(membership);

    this.memberships = [...this.memberships, membership];
    this.currentMembership = membership;
    this.teams = [...this.teams, membership.team!];
    this.api.dispatch(ClarityTeamEvent.TEAM_CREATED, membership.team);
    this.api.dispatch(ClarityDocsEvent.API_MESSAGE, 'Team created!');
  }

  public async deleteTeam(id: string) {
    await this.client.delete('/team/' + id);

    this.memberships = this.memberships.filter(m => m.teamId !== id);

    if (this.currentMembership?.teamId === id) {
      this.currentMembership = this.memberships[0];
    }

    this.teams = this.teams.filter(t => t.id !== id);
    this.api.dispatch(ClarityTeamEvent.TEAM_DELETED, id);
    this.api.dispatch(ClarityTeamEvent.TEAMS_UPDATED, this.teams);
    this.api.dispatch(ClarityDocsEvent.API_MESSAGE, 'Team deleted!');
  }

  public getTeamCached(id: string): TeamModel | null {
    return this.teams.find(t => t.id === id) || null;
  }

  @KeepPromise()
  public async getTeam(id: string) {
    const team = await this.client.get<TeamModel>('/team/' + id)
      .then(({ data }) => plainToInstance(TeamModel, data, { excludeExtraneousValues: true }));

    this.teams = this.teams.map(t => t.id === id ? team : t);

    return team;
  }

  public async leaveTeam(teamId: string) {
    await this.client.patch(`/user/leave/${teamId}`);
    this.memberships = this.memberships.filter(m => m.teamId !== teamId)!;
    this.currentMembership = this.memberships[0];
    this.teams = this.teams.filter(t => t.id !== teamId);
  }

  public setMembership(id: string) {
    this.currentMembership = this.memberships.find(m => m.id === id) || this.memberships[0];
  }

  public getMember(userId: string) {
    return this.userRecords[userId] || null;
  }

  public async registerMember(member: UserModel) {
    this.userRecords[member.id] = member;
  }

  public async changeRole(membershipId: string, roleId: Role) {
    const payload = {
      roleId,
    };

    const membership = this.membershipRecords[membershipId];
    const team = this.getTeamCached(membership.teamId)!.clone();

    if (!membership) return;

    await this.client.patch(`/member/${membershipId}/role`, payload);
    membership.role = roleId;

    team.memberships = team.memberships.map(m => m.id === membershipId ? membership : m);
    this.teams = this.teams.map(t => t.id === team.id ? team : t);

    this.api.dispatch(ClarityTeamEvent.TEAM_UPDATED, team);
    this.api.dispatch(ClarityDocsEvent.API_MESSAGE, 'Role changed!');
  }

  public async deleteMembership(membershipId: string) {
    const membership = this.membershipRecords[membershipId];
    const team = this.getTeamCached(membership.teamId)!.clone();

    await this.client.delete(`/member/${membershipId}`);

    team.memberships = team.memberships.filter(m => m.id !== membershipId);
    this.teams = this.teams.map(t => t.id === team.id ? team : t);

    this.api.dispatch(ClarityTeamEvent.TEAM_UPDATED, team);
    this.api.dispatch(ClarityDocsEvent.API_MESSAGE, 'Member removed!');
  }

  @KeepPromise()
  public async getInvitations(teamId: string) {
    const team = this.getTeamCached(teamId)!;

    if (!team) return [];

    if (team.invitations && team.invitations.length) {
      return team.invitations;
    }

    const { data } = await this.client.get<InvitationModel[]>(`/team/${teamId}/invitations`);

    const invitations = plainToInstance(InvitationModel, data, { excludeExtraneousValues: true });
    team.invitations = invitations;

    this.api.dispatch(ClarityTeamEvent.TEAM_UPDATED, team);

    return team.invitations;
  }

  public async sendInvitation(requestInfo: InvitationRequestModel) {
    const team = this.getTeamCached(requestInfo.teamId)!;

    const { data } = await this.client.post<InvitationModel>('/invitation', requestInfo);
    const invitation = plainToInstance(InvitationModel, data, { excludeExtraneousValues: true });
    team.invitations = [...team.invitations, invitation];
    this.api.dispatch(ClarityTeamEvent.TEAM_UPDATED, team);
    this.api.dispatch(ClarityDocsEvent.API_MESSAGE, 'Invitation sent!');
  }

  public async revokeInvitation(invitation: InvitationModel) {
    const team = this.getTeamCached(invitation.teamId)!;

    await this.client.delete('/invitation/' + invitation.id);

    team.invitations = team.invitations.filter(i => i.id !== invitation.id);
    this.api.dispatch(ClarityTeamEvent.TEAM_UPDATED, team);
    this.api.dispatch(ClarityDocsEvent.API_MESSAGE, 'Invitation revoked!');
  }

  public async updateLogo(teamId: string, file: File) {
    const formData = new FormData();
    formData.append('contents', file);

    const { data } = await this.client.patch<LogoModel>(`/team/${teamId}/logo`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    });

    const team = this.getTeamCached(teamId)!.clone({ logo: data });
    this.teams = this.teams.map(t => t.id === teamId ? team : t);
    this.api.dispatch(ClarityTeamEvent.TEAM_UPDATED, team);
    this.api.dispatch(ClarityDocsEvent.API_MESSAGE, 'Logo updated!');
  }

  @KeepPromise()
  public async getInvitation(inviteToken: string) {
    const { data } = await this.client.get<InvitationModel>('/invitation/' + inviteToken);
    return plainToInstance(InvitationModel, data, { excludeExtraneousValues: true });
  }

  public async acceptInvitation(inviteToken: string) {
    await this.client.patch('/invitation/' + inviteToken);
  }

  @KeepPromise()
  public async getSubscription(teamId: string) {
    const { data } = await this.client.get<SubscriptionModel>(`/team/${teamId}/subscription`);
    const subscription = plainToInstance(SubscriptionModel, data, { excludeExtraneousValues: true });

    return subscription;
  }

  @KeepPromise()
  public async getPlans() {
    const { data } = await this.client.get<SubscriptionPlanModel[]>('/plans');
    return plainToInstance(SubscriptionPlanModel, data, { excludeExtraneousValues: true });
  }

  public async getCheckoutPlanUrl(teamId: string, planId: string) {
    // NOTE: Stripe URL is returned
    const { data } = await this.client.post<{ url: string }>(`/checkout`, {
      planId,
      teamId
    });

    return data.url;
  }

  public async getBilingPortalUrl(teamId: string) {
    const { data } = await this.client.post<{ url: string }>(`/billing-portal`, {
      teamId
    });

    return data.url;
  }

  public getRoleLabel(role: Roles[keyof Roles]) {
    return this.roleList.find(r => r.synonym === role || r.id === role)?.name || '';
  }
}
