import { MutableRefObject, useCallback, useRef } from "react";
import { getPayload } from "src/utils/objectUtils";

export interface FormControls {
  validate: () => boolean;
  assignValues: (values: Record<string, any>) => void;
  clearForm: () => void;
}

interface FormProps {
  children?: React.ReactNode;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  className?: string;
  controls?: MutableRefObject<FormControls | null>;
  id?: string;
}

export const Form = (props: FormProps) => {
  const formRef = useRef<HTMLFormElement>(null);

  const validate = useCallback(() => {
    const { current: form } = formRef;
    
    if (!form) {
      return true;
    }

    const payload = getPayload(form);
    
    const errors = Array.from(form.elements)
      .map((element) => {
        if (!("_validate" in element)) {
          return null;
        }

        const errors = (element as any)._validate(payload);

        if (!errors) {
          return null;
        }

        return errors.join(', ');
      })
      .filter(Boolean);

    return errors.length === 0;
  }, []);

  const validateAndSubmit = useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();

    if (!validate()) return;

    props.onSubmit(e);
  }, [validate, props]);

  const assignValues = useCallback((values: Record<string, any>) => {
    const { current: form } = formRef;

    if (!form) {
      return;
    }

    Array.from(form.elements).forEach((element) => {
      if (!("_assignValue" in element)) {
        return;
      }
      const el = element as any;

      el._assignValue(values[el.name]);
    });
  }, []);

  const clearForm = useCallback(() => {
    const { current: form } = formRef;

    if (!form) {
      return;
    }

    Array.from(form.elements).forEach((element) => {
      if (!("_assignValue" in element)) {
        return;
      }
      const el = element as any;

      el._assignValue('');
    });
  }, []);

  if (props.controls) {
    props.controls.current = {
      validate,
      assignValues,
      clearForm,
    };
  }

  return <form 
    className={ props.className } 
    onSubmit={ validateAndSubmit }
    ref={ formRef }
    id={ props.id }
  >
    { props.children }
  </form>
}
