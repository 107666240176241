import clsx from "clsx";
import { useCallback, useState } from "react";

import { OverlayScrollbarsComponentRef } from "overlayscrollbars-react";
import { useChat } from "src/api/hooks";
import { TextField } from "src/components/ui";
import { getPayload } from "src/utils/objectUtils";
import { MessagePayload } from "src/api/services/chat.service";

interface ChatFormProps {
  documentId: string;
  folderId: string;
  scrollRef: React.RefObject<OverlayScrollbarsComponentRef>;
  screenshot: string | null;
  onResetScreenshot: () => void;
}

export const ChatForm = (props: ChatFormProps) => {
  const { scrollRef } = props;
  const { 
    isSendingMessage,
    sendMessage,
    abortMessage,
  } = useChat(props.folderId!);

  const messageState = useState('');

  const onSubmit: React.FormEventHandler = useCallback((event) => {
    event.preventDefault();

    if (isSendingMessage) {
      abortMessage();
      return;
    }

    const { target } = event as unknown as { target: HTMLFormElement };
    const payload = getPayload<MessagePayload>(target);
    sendMessage(payload);
    messageState[1]('');
    props.onResetScreenshot();

    const scrollEl = scrollRef.current?.getElement()?.children[1] as HTMLDivElement;

    if (!scrollEl) {
      return;
    }

    scrollEl.scrollTop = scrollEl.scrollHeight;
  }, [sendMessage, abortMessage, messageState, isSendingMessage, scrollRef, props]);

  return <form 
    className={ clsx({
      "chat__form": true,
      "chat__form--has-attachment": !!props.screenshot,
    }) }
    onSubmit={ onSubmit }
  >
    <button
      className={clsx({
        "chat__attachment": true,
      })}
      type="button"
      onClick={ props.onResetScreenshot }
      id="chat-attachment"
    >
      <img
        src={ props.screenshot || "" }
        alt="Attachment"
      />
    </button>

    { 
      props.screenshot 
      && <input type="hidden" name="attachment" value={ props.screenshot } /> 
    }

    <TextField
      name="message"
      pending = { isSendingMessage }
      className="chat__input"
      placeholder={ !isSendingMessage ? "Ask Clarity anything..." : "Processing..." }
      statePair={ messageState }
      maxLength={ 2048 }
    />

    <button
      type="submit"
      className={clsx({
        'chat__send-button': true,
        'chat__send-button--pending': isSendingMessage,
      })}
      disabled={ messageState[0].length === 0 && !isSendingMessage }
    >
      <i className={clsx({
        'icon-paper-plane': !isSendingMessage,
        'icon-stop': isSendingMessage,
      })}></i>
    </button>
  </form>
}
