import 'notyf/notyf.min.css';
import { Notyf } from "notyf";
import { ClarityDocsApi, ClarityDocsEvent } from 'src/api';

export const notyf = new Notyf();

export const showErrorMessage = (message: string) => {
  notyf.error(message);
}

export const showMessage = (message: string) => {
  notyf.success(message);
}

const session = new ClarityDocsApi();

session.on(ClarityDocsEvent.API_ERROR_MESSAGE, showErrorMessage);
session.on(ClarityDocsEvent.API_MESSAGE, showMessage);
