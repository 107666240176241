import { useCallback, useState } from "react";
import { ClarityDocsApi } from "src/api";
import { useTeam } from "src/api/hooks/useTeam";
import { PopupProps } from "src/components/ui";
import { ConfirmPopup } from "../../ConfirmPopup";

const session = new ClarityDocsApi();

interface TeamDeleteConfirmPopupProps extends Omit<PopupProps, 'children'> {
  onRemoved: () => void;
}

export const TeamDeleteConfirmPopup = (props: TeamDeleteConfirmPopupProps) => {
  const [isPending, setIsPending] = useState(false);
  const { team } = useTeam();

  const confirmDelete = useCallback(() => {
    if (!team) return;

    setIsPending(true);
    session.team.deleteTeam(team.id)
      .then(() => {
        props.onRemoved();
        props.statePair[1](false);
      })
      .finally(() => {
        setIsPending(false);
      });
  }, [team, props]);

  return <ConfirmPopup
    statePair={ props.statePair }
    title="Delete team"
    message="Are you sure you want to delete this team?"
    isPending={ isPending }
    onConfirm={ confirmDelete }
    confirmText="Delete"
    danger
  />
}
