import { Expose, Type } from "class-transformer";
import { MessageModel } from "./message.model";

export enum MessageMetaType {
  MESSAGE = "Message",
  MESSAGE_UPDATE = "MessageUpdate",
  MESSAGE_START = "MessageStart",
  MESSAGE_END = "MessageEnd",
}

export class MessageMeta {
  @Expose()
  public type: MessageMetaType;

  @Expose()
  public messageId: string;

  @Expose()
  public content: string;

  @Expose()
  @Type(() => MessageModel)
  public message: MessageModel;
}
