import { Link } from "react-router-dom";

import { SignUpModel } from "src/api/models/signUp.model";
import { rules } from "src/utils/validation";
import { getPayload } from "src/utils/objectUtils";

import { ArtworkLayout } from "src/components/layouts/ArtworkLayout";
import { GoogleSignInButton } from "src/components/shared/GoogleSignInButton";
import { TextField, Form } from "src/components/ui";

import { useUser } from "src/api/hooks";
import { resolveRoute, useNavigate } from "src/router/routes";

import IMAGE_ARTWORK from 'src/assets/images/artwork.jpg';
import { LoginGuard } from "src/guards/login.guard";
import clsx from "clsx";

export const Name = "signup";

export const Loader = LoginGuard('signup');

export const Page = () => {
  const navigate = useNavigate();
  const { signUp, isPending } = useUser();

  const onSubmit: React.FormEventHandler = async (e) => {
    e.preventDefault();
    const payload = getPayload<SignUpModel>(e.currentTarget as HTMLFormElement);
    const success = await signUp(payload);

    if (!success) {
      return;
    }

    navigate({ name: 'main' });
  };

  return <ArtworkLayout imageSrc={ IMAGE_ARTWORK }>
    <Form
      className="flex flex--col flex--gap48"
      onSubmit={ onSubmit }
    >
      <h1 className="tx-center tx-section-heading"> Create new account </h1>
      <div className="flex flex--col flex--gap30">
        <div className="flex flex--gap">
          <TextField
            label="First name" 
            name="firstName"
            placeholder="Enter first name"
            rules={[
              rules.required(),
            ]}
          />

          <TextField
            label="Last name" 
            name="lastName"
            placeholder="Enter last name"
            rules={[
              rules.required(),
            ]}
          />
        </div>

        <TextField
          label="Email" 
          name="email"
          placeholder="Enter email address"
          rules={[
            rules.required(),
            rules.email(),
          ]}
        />

        <TextField 
          label="Password" 
          name="password"
          placeholder="Password"
          password
          rules={[
            rules.required(),
          ]}
        />

        <button
          type="submit"
          className={ clsx({
            "button button--primary button--fluid": true,
            "button--pending": isPending,
          }) }
          children="Sign up"
        />

        <p className="tx-center txc-placeholder">
          Already have an account? <Link to={ resolveRoute({ name: "signin" }) }>Sign in</Link>
        </p>

        <div className="divider" children="or" />

        <GoogleSignInButton 
          label="Sign up with Google"
          disabled={ isPending } 
        />
      </div>
    </Form>
  </ArtworkLayout>;
}
