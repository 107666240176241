import clsx from "clsx";
import { useCallback, useRef, useEffect } from "react";
import { useFolders } from "src/api/hooks";
import { TextField, Popup, PopupProps, Form, FormControls } from "src/components/ui";
import { rules } from "src/utils/validation";
import { useNavigate } from "src/router/routes";
import { FolderUpdateModel } from "src/api/models/folder.model";
import { getPayload, pick } from "src/utils/objectUtils";

interface CreateFolderPopupProps extends Omit<PopupProps, 'title' | 'children'> {
  edit?: boolean;
  folderId?: string;
}

export const CreateFolderPopup = (props: CreateFolderPopupProps) => {
  const { createFolder, updateFolder, isPending, getFolder } = useFolders();
  const formControlRef = useRef<FormControls | null>(null);
  const navigate = useNavigate();

  const nextButtonLabel = props.edit ? "Save" : "Create";
  const title = props.edit ? "Edit folder" : "Create new folder";

  const closePopup = useCallback((createdFolderId?: string) => {
    props.statePair[1](false);
    if (!createdFolderId) {
      return;
    }

    navigate({
      name: 'folder',
      params: {
        folderId: createdFolderId,
      },
    });
  }, [navigate, props.statePair]);

  const onSubmit = useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const payload = getPayload<FolderUpdateModel>(e.currentTarget);

    if (props.edit) {
      await updateFolder(props.folderId!, payload);
      closePopup();
      return;
    }

    const folder = await createFolder(payload);

    if (!folder) {
      return;
    }

    closePopup(folder.id);
  }, [createFolder, props.edit, closePopup, updateFolder, props.folderId]);

  useEffect(() => {
    if (!props.edit || !props.statePair[0]) {
      return;
    }

    const folder = getFolder(props.folderId!);

    if (!folder) {
      return;
    }

    formControlRef.current?.assignValues(pick(folder, ['name', 'description', 'topic']));
  }, [props.folderId, props.edit, getFolder, props.statePair]);

  const cancelButtonClasslist = clsx({
    "button": true,
    "button--disabled": isPending,
  });

  const createButtonClasslist = clsx({
    "button": true,
    "button--primary": true,
    "button--pending": isPending,
  });

  return <Popup 
    { ...props } 
    title={ title }
    width={655}
  >
    <Form
      onSubmit={ onSubmit }
      className="flex flex--col flex--gap24"
      controls={ formControlRef }
    >
      <div 
        className="flex flex--col flex--gap24"
      >
        <TextField 
          label="Folder name" 
          name="name"
          placeholder="Enter the folder name"
          rules={[
            rules.required(),
          ]}
        />

        { props.edit && <>
          <TextField
            name="description"
            label="Description"
            multiline
            placeholder="Enter the folder description"
          />

          <TextField 
            label="Topic" 
            name="topic"
            placeholder="Enter the folder topic"
          />
        </> }
      </div>

      <div className="flex flex--jend flex--acenter">
        { !props.persistent && <button
          type="button"
          className={ cancelButtonClasslist }
          onClick={ () => closePopup() }
        >
          Cancel
        </button> }

        <button
          type="submit"
          className={ createButtonClasslist }
        >
          { nextButtonLabel }
        </button>
      </div>
    </Form>
  </Popup>;
};
