import "./index.scss";

interface MetricProps {
  name: string;
  icon: string;
  max: number;
  value: number;
}

export const Metric = (props: MetricProps) => {
  const progress = (props.value / props.max) * 100;

  return <div className="metric">
    <p
      className="metric__name"
      children={ props.name }
    />

    <div
      className="metric__progress"
      style={ {
        '--progress': `${Math.min(100, progress)}%`
      } as React.CSSProperties }
    />

    <div className="metric__values">
      <p>
        { props.icon && <i className={ "icon-" + props.icon } /> }
        { `${props.value} (${ progress.toFixed(0) }%)` }
      </p>
      <p children={ props.max } />
    </div>
  </div>
}
