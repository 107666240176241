import { Expose, Transform, Type } from "class-transformer";
import { UserModel } from "./member.model";
import type { DocumentModel } from "./document.model";
import { MessageModel } from "./message.model";

export class FolderModel {
  @Expose()
  public id: string;

  @Expose()
  public name: string;

  @Expose()
  public teamId: string;

  @Expose()
  public description: string;

  @Expose()
  public topic: string;

  @Expose()
  @Transform((params) => new Date(params.value))
  public createdAt: Date;

  @Expose()
  @Type(() => UserModel)
  public createdBy: UserModel;

  private documents_: DocumentModel[] = [];
  public documentRecords: Record<string, DocumentModel> = {};

  public get documents() {
    return this.documents_;
  }

  public set documents(value: DocumentModel[]) {
    this.documents_ = value;
    this.documents_.forEach(doc => {
      this.documentRecords[doc.id] = doc;
    });
  }

  public messages: Record<string, MessageModel> = {};

  public get initials() {
    return this.name
      .split(' ')
      .map((word) => word[0])
      .slice(0, 2)
      .join('')
      .toUpperCase();
  }

  public get pinnedDocuments() {
    return Object.values(this.documents)
      .filter((doc) => doc.isPinned);
  }
}

export type FolderUpdateModel = Pick<FolderModel, 'name' | 'description' | 'topic'>;
