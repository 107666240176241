import { Expose } from "class-transformer";

export abstract class FileModel {
  @Expose()
  id: string;

  @Expose()
  name: string;

  @Expose()
  contentType: string;

  @Expose()
  url: string;

  @Expose()
  createdAt: string;

  @Expose()
  length: number;
}
