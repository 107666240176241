import { useCallback, useEffect, useState } from "react";
import { ClarityDocsApi } from "..";
import { DocumentModel } from "../models/document.model";
import { ClarityDocumentEvent } from "../services/document.service";

const session = new ClarityDocsApi();

export const useDocuments = (folderId?: string) => {
  const folder = session.folders.get(folderId!);

  const [documents, setDocuments] = useState<DocumentModel[]>(folder?.documents || []);
  const [isPending, setIsPending] = useState(true);
  const [isInvalidFolder] = useState(!folder);
  
  const getDocument = useCallback((id: string) => session.documents.get(id) || null, []);

  const deleteDocument = useCallback(async (id: string) => {
    setIsPending(true);
    return session.documents.delete(id)
      .finally(() => setIsPending(false));
  }, []);

  const pinDocument = useCallback(async (id: string) => {
    session.documents.pin(id);
  }, []);

  const unpinAllDocuments = useCallback((folderId: string) => {
    session.documents.unpinAll(folderId);
  }, []);

  // NOTE: Reinitialize documents when folderId changes
  useEffect(() => {
    if (!folderId) {
      return;
    }

    setDocuments([]);
    setIsPending(true);
    session.documents.getList(folderId)
      .then(list => setDocuments(list))
      .finally(() => setIsPending(false));
    
    const unsubListUpdated = session.on(
      ClarityDocumentEvent.DOCUMENTS_UPDATED,
        ({ list, folder }) => {
        if (folder.id !== folderId) {
          return;
        }

        setDocuments(list);
      });

    const unsubDocumentUpdated = session.on(
      ClarityDocumentEvent.DOCUMENT_UPDATED,
      doc => {
        if (doc.folderId !== folderId) {
          return;
        }

        setDocuments(docs => docs.map(d => d.id === doc.id ? doc : d));
      });

    return () => {
      unsubListUpdated();
      unsubDocumentUpdated();
    }
  }, [folderId]);

  return {
    documents,
    isPending,
    isInvalidFolder,
    session,
    getDocument,
    deleteDocument,
    pinDocument,
    unpinAllDocuments,
  };
}
