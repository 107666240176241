import clsx from "clsx";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTeam } from "src/api/hooks/useTeam";
import { TeamModel } from "src/api/models/team.model";
import { Form, FormControls, TextField } from "src/components/ui";
import { getPayload, pick } from "src/utils/objectUtils";
import { rules } from "src/utils/validation";
import { AvatarUploader } from "src/components/shared/AvatarUploader";
import type { SettingsTabProps } from "..";
import { TeamDeleteConfirmPopup } from "./TeamDeleteConfirmPopup";
import { ClarityDocsApi } from "src/api";
import { useMembership } from "src/api/hooks";

const session = new ClarityDocsApi();

export const GeneralTab = (props: SettingsTabProps) => {
  const formControlsRef = useRef<FormControls | null>(null);
  const teamDeleteState = useState(false);
  const { allowedFor } = useMembership();
  const { team, updateTeam, isPending, updateLogo } = useTeam();
  const [isLoading, setLoading] = useState(true);

  const saveSettings = useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
    const payload = getPayload<TeamModel>(e.currentTarget as HTMLFormElement);

    updateTeam(payload);
  }, [updateTeam]);

  useEffect(() => {
    const { current: formControls } = formControlsRef;

    if (!formControls || !team) {
      return;
    }

    formControls.assignValues(pick(team, ['name', 'website', 'description']));
  }, [team]);

  useEffect(() => {
    if (!team) return;

    session.team.getTeam(team.id)
      .then(() => setLoading(false));
  }, [team]);

  return <div className="flex flex--col flex--gap24">
    <div className={clsx({
      "flex": true,
      "skeleton skeleton--light": true,
      "skeleton--active": isLoading,
    }) }>
      <Form 
        className="flex flex--col flex--gap30 flex-auto"
        onSubmit={ saveSettings }
        controls={ formControlsRef }
        id="mainForm"
      >
        <TextField
          label="Name"
          name="name"
          rules={[
            rules.required(),
          ]}
        />

        <TextField
          label="Description"
          name="description"
          multiline
        />

        <TextField
          label="Website"
          name="website"
        />
      </Form>
      <div>
        <AvatarUploader
          name="avatar"
          entityName={ team?.name }
          label="Logo"
          src={ team?.logo?.url }
          onChange={ updateLogo }
        />
      </div>
    </div>

    <div className="flex flex--jbetween">
      <div>
        { allowedFor(session.team.roles.OWNER) && <button
          className="button button--danger"
          children="Delete team"
          onClick={ () => teamDeleteState[1](true) }
        /> }
      </div>

      <div className="flex">
        <button
          className={clsx({
            'button button--primary': true,
            'button--pending': isPending, })}
          type="submit"
          form="mainForm"
        >
          Save changes
        </button>
        <button
          className="button"
          onClick={ props.requestClose }
          children="Cancel"
        />
      </div>
    </div>

    <TeamDeleteConfirmPopup 
      statePair={ teamDeleteState } 
      onRemoved={ props.requestClose }
    />
  </div>
}
