import { AxiosInstance } from "axios";
import { plainToInstance } from "class-transformer";
import type { ClarityDocsApi } from "..";
import { FolderModel } from "../models/folder.model";
import { ClarityTeamEvent } from "./team.service";

export enum ClarityFoldersEvent {
  FOLDERS_UPDATED = "FOLDERS_UPDATED",
  FOLDER_CREATED = "FOLDER_CREATED",
  FOLDER_UPDATED = "FOLDER_UPDATED",
  FOLDER_DELETED = "FOLDER_DELETED",
}

export class FoldersService {
  private client: AxiosInstance;

  constructor(private api: ClarityDocsApi) {
    this.client = (api as any).client as AxiosInstance;

    this.api.on(ClarityTeamEvent.USER_MEMBERSHIP_CHANGED, () => {
      this.init();
    });

    this.api.on(ClarityTeamEvent.TEAM_DELETED, (teamId) => {
      this.list = this.list.filter(f => f.teamId !== teamId);
    });
  }

  private folderRecords: Record<string, FolderModel> = {};
  private list_: FolderModel[] = [];

  public get list() {
    return this.list_;
  }

  private set list(value: FolderModel[]) {
    this.list_ = value;
    this.api.dispatch(ClarityFoldersEvent.FOLDERS_UPDATED);
  }

  public async init() {
    const { currentMembership: membership } = this.api.team;

    if (!membership) {
      return;
    }

    const { data } = await this.client.get<FolderModel[]>(`team/${membership.teamId}/folders`);
    this.list = plainToInstance(FolderModel, data, { excludeExtraneousValues: true });

    this.list.forEach(folder => {
      this.folderRecords[folder.id] = folder;
    });

    this.api.dispatch(ClarityFoldersEvent.FOLDERS_UPDATED, this.list);
  }

  /**
   * Returns a folder by its ID
   */
  public get(folderId: string) {
    return this.folderRecords[folderId] || null;
  }

  /**
   * Creates a new folder
   */
  public async create(payload: Pick<FolderModel, 'name' | 'description' | 'topic'>) {
    const { data } = await this.client.post('/folder', {
      ...payload,
      teamId: this.api.team.currentMembership!.teamId,
    });

    const folder = plainToInstance(FolderModel, data, { excludeExtraneousValues: true });
    this.folderRecords[folder.id] = folder;
    this.list = [...this.list, folder];

    this.api.dispatch(ClarityFoldersEvent.FOLDERS_UPDATED, this.list);
    this.api.dispatch(ClarityFoldersEvent.FOLDER_CREATED, folder);

    return folder;
  }

  /**
   * Updates a folder by its ID
   */
  public async update(folderId: string, payload: Pick<FolderModel, 'name' | 'description' | 'topic'>) {
    const folder = this.get(folderId);

    await this.client.patch(`/folder/${folderId}`, payload);

    Object.assign(folder, payload)

    this.api.dispatch(ClarityFoldersEvent.FOLDERS_UPDATED, this.list);
    this.api.dispatch(ClarityFoldersEvent.FOLDER_UPDATED, folder);

    return folder;
  }

  /**
   * Removes a folder by its ID
   */
  public async delete(folderId: string) {
    const folder = this.folderRecords[folderId];

    if (!folder) {
      return;
    }

    await this.client.delete(`/folder/${folderId}`);

    delete this.folderRecords[folderId];
    this.list = this.list.filter(f => f.id !== folderId);

    this.api.dispatch(ClarityFoldersEvent.FOLDERS_UPDATED, this.list);
    this.api.dispatch(ClarityFoldersEvent.FOLDER_DELETED, folder);
  }
}
