import clsx from 'clsx';
import { cloneElement, useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { mergeRefs } from 'src/utils/objectUtils';
import './index.scss';

export enum TooltipPosition {
  TOP = 'top',
  RIGHT = 'right',
  BOTTOM = 'bottom',
  LEFT = 'left',
};

interface TooltipProps {
  position?: TooltipPosition;
  content: React.ReactNode;
  children: React.ReactNode;
  passive?: boolean;
}

export interface TooltipInjections extends HTMLDivElement {
  _repositeTooltip: () => void;
  _setActive: (active: boolean) => void;
}

export const Tooltip = (props: TooltipProps) => {
  const { position = TooltipPosition.TOP, content } = props;
  let { children } = props;

  const [isActive, setIsActive] = useState(false);

  const tooltipRef = useRef<HTMLDivElement | null>(null);
  const tooltipClasslist = clsx('tooltip', {
    [`tooltip--${position}`]: true,
    'tooltip--active': isActive,
  });
  const childRef = useRef<HTMLDivElement | null>(null);

  children = Array.isArray(props.children)
    ? props.children
    : [props.children];

  children = (children as React.ReactElement[])
    .map((child, index) => {
      if (index === 0) {
        return cloneElement(child, {
          ref: mergeRefs((child as any).ref, childRef),
          onMouseEnter: () => !props.passive && setIsActive(true),
          onMouseLeave: () => !props.passive && setIsActive(false),
          key: index,
        });
      }

      return child;
    });

  const repositeTooltip = useCallback(() => {
    if (!tooltipRef.current || !childRef.current) {
      return;
    }

    const childRect = childRef.current.getBoundingClientRect();
    const tooltipRect = tooltipRef.current.getBoundingClientRect();

    let top = 0;
    let left = 0;

    switch (position) {
      case TooltipPosition.TOP:
        top = childRect.top - tooltipRect.height;
        left = childRect.left + childRect.width / 2 - tooltipRect.width / 2;
        break;

      case TooltipPosition.RIGHT:
        top = childRect.top + childRect.height / 2 - tooltipRect.height / 2;
        left = childRect.right;
        break;

      case TooltipPosition.BOTTOM:
        top = childRect.bottom;
        left = childRect.left + childRect.width / 2 - tooltipRect.width / 2;
        break;

      case TooltipPosition.LEFT:
        top = childRect.top + childRect.height / 2 - tooltipRect.height / 2;
        left = childRect.left - tooltipRect.width;
        break;
    }

    tooltipRef.current.style.top = `${top}px`;
    tooltipRef.current.style.left = `${left}px`;
  }, [position]);

  const tooltip = createPortal(
    <div
      className={ tooltipClasslist }
      ref={ tooltipRef }
    >
      { content }
    </div>,
    document.body
  );

  useEffect(() => {
    repositeTooltip();
  }, [isActive, repositeTooltip]);

  useEffect(() => {
    if (!childRef.current) {
      return;
    }

    Object.assign(childRef.current as TooltipInjections, {
      _repositeTooltip: repositeTooltip,
      _setActive: setIsActive,
    });
  }, [repositeTooltip]);

  return <>
    { children }
    { tooltip }
  </>;
};
