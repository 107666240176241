import { useEffect, useState } from "react";

import { ClarityDocsApi } from "..";
import { ClarityTeamEvent } from "../services/team.service";

import { MembershipModel } from "../models/membership.model";
import { TeamModel, TeamUpdateModel } from "../models/team.model";
import { useNavigate } from "src/router/routes";

const session = new ClarityDocsApi();

export const useTeam = () => {
  const [isPending, setIsPending] = useState(false);
  const [team, setTeam] = useState<TeamModel | null>(session.team.currentTeam);
  const [teams, setTeams] = useState<TeamModel[]>(session.team.teams);
  const navigate = useNavigate();

  useEffect(() => {
    const currentMembership = session.team.currentMembership;
    const unsubMembership = session
      .on(ClarityTeamEvent.USER_MEMBERSHIP_CHANGED, (membership: MembershipModel | undefined) => {
        if (!membership) {
          navigate({ name: 'create-team' });
          return;
        }
        setTeam(session.team.getTeamCached(membership.teamId)!);
      })

    const unsubTeams = session.on(ClarityTeamEvent.TEAMS_UPDATED, setTeams);
    const unsubTeam = session.on(ClarityTeamEvent.TEAM_UPDATED, (_team) => {
      if (team?.id !== _team?.id) return;
      setTeam(_team);
    });

    if (!team && currentMembership) {
      setTeam(session.team.getTeamCached(currentMembership?.teamId)!);
    }

    return () => {
      unsubMembership();
      unsubTeams();
      unsubTeam();
    }
  }, [team, teams, navigate, setTeam, setTeams]);


  const updateTeam = async (payload: TeamUpdateModel) => {
    setIsPending(true);

    return session.team.updateTeam(team!.id, payload)
      .then((res) => {
        setTeam(res);
        return res;
      })
      .finally(() => setIsPending(false));
  }

  const updateLogo = async (file: File) => {
    setIsPending(true);

    return session.team.updateLogo(team!.id, file)
      .finally(() => setIsPending(false));
  }

  return {
    team,
    teams,
    isPending,

    updateTeam,
    updateLogo,
  };
}
