const activePromises = new Map<Promise<void>, () => void>();

export const cancelTimeout = (timeoutPromise: Promise<void> | null) => {
  if (!timeoutPromise) {
    return;
  }

  const cancel = activePromises.get(timeoutPromise);

  if (cancel) {
    cancel();
    activePromises.delete(timeoutPromise);
  }
}

export const setTimeoutProgress = (callable: Function, duration: number) => {
  let timestamp = 0;
  let resolver: Function | null = null;
  let rejector: Function | null = null;
  let rejected = false;

  const animate = (time: number) => {
    if (!timestamp) {
      timestamp = time;
    }

    if (rejected) {
      return;
    }

    const progress = time - timestamp;
    callable(progress / duration);

    if (progress >= duration) {
      resolver!();
      return;
    }

    requestAnimationFrame(animate);
  }

  animate(timestamp);

  const promise = new Promise<void>((resolve, reject) => {
    resolver = resolve;
    rejector = reject;
  });

  activePromises.set(promise, () => {
    rejector!();
    rejected = true;
  });

  return promise;
}

export const easeOutQuad = (t: number) => {
  return t * (2 - t);
}
