import './index.scss';

import { Link, useParams } from 'react-router-dom';
import clsx from 'clsx';
import { useCallback, useEffect, useRef } from 'react';

import { DocumentModel } from 'src/api/models/document.model';
import { Tooltip } from 'src/components/ui';

type DocumentButtonProps = {
  document: DocumentModel;
  onDeleteClick?: (doc: DocumentModel) => void;
  onPinClick?: (doc: DocumentModel) => void;
  skeleton?: boolean;
}

export const DocumentButton = (props: DocumentButtonProps) => {
  const params = useParams();
  const titleRef = useRef<HTMLDivElement>(null);
  const { document: doc, onDeleteClick, onPinClick } = props;

  const deleteHandler = useCallback(() => {
    onDeleteClick?.(doc);
  }, [onDeleteClick, doc]);

  const togglePin = useCallback(() => {
    onPinClick?.(doc);
  }, [onPinClick, doc]);

  useEffect(() => {
    const { current: el } = titleRef;
    if (!el) {
      return;
    }

    const scrollDistance = el.scrollWidth - el.clientWidth;

    el.style.setProperty('--title-scroll-width', scrollDistance + 'px');
    if (scrollDistance) {
      el.dataset.useScroll = 'true';
    }
  }, []);

  return <div
    className={clsx({
      'document-button': true,
      'document-button--active': props.document.id === params.documentId && !props.skeleton,
      'skeleton skeleton--active': props.skeleton,
    })}
  >
    <div className="flex flex--jbetween">
      { doc.folderId && <Link
        to={ `/main/${doc.folderId}/${doc.id}` }
        className="document-button__title"
        // @ts-ignore For some reason Router providers old types of the Link API
        ref={ titleRef }
      >
        <div> { doc.name } </div>
      </Link> }
      { !doc.folderId && <p className="document-button__title">
        Undefined document
      </p> }

      <div className="document-button__actions">
        <Tooltip content="Delete document">
          <button onClick={ deleteHandler }>
            <i className="icon-delete"></i>
          </button>
        </Tooltip>
        <Tooltip content="Pin document">
          <button onClick={ togglePin }>
            <i className={doc.isPinned ? "icon-pin-filled" : "icon-pin" }></i>
          </button>
        </Tooltip>
      </div>
    </div>

    <div className="document-button__stats">
      <div className="flex flex--acenter flex--gap8">
        <i className="icon-document"></i> 
        <span> { doc.pageCount } pages </span>
      </div>
    </div>
  </div>
}
