import './index.scss';

import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

import ILL_NO_FILES from 'src/assets/images/no-files.svg';
import { useDocuments } from 'src/api/hooks';
import { useCallback, useEffect, useState } from 'react';
import { DocumentModel } from 'src/api/models/document.model';
import { useNavigate } from 'src/router/routes';
import { DocumentButton } from '../DocumentButton';
import { ConfirmPopup } from '../ConfirmPopup';
import { useParams } from 'react-router-dom';

interface DocumentGroupProps {
  title?: string;
  actionTitle?: string;
  onActionClick?: () => void;
  children: React.ReactNode;
}

interface DocumentListProps {
  folderId: string;
  searchQuery: string;
  documentId?: string;
  isPendingFolder?: boolean;
}

const NO_DOCUMENTS_MESSAGE = <div className="flex flex--col flex--acenter">
  <img src={ ILL_NO_FILES } alt="" />
  <p>No documents yet</p>
</div>

const DocumentGroup = (props: DocumentGroupProps) => {
  if (Array.isArray(props.children) && !props.children.length) {
    return null;
  }

  if (!props.children) {
    return null;
  }

  return <div className="document-group">
    { !!props.title && <div className="document-group__header">
      <p className="document-group__title">{ props.title }</p>
      { !!props.onActionClick && <button onClick={ props.onActionClick }>
        { props.actionTitle }
      </button> }
    </div> }

    <div className="document-group__list">
      { props.children }
    </div>
  </div>;
}

export const DocumentList = (props: DocumentListProps) => {
  const {
    documents, isPending,
    deleteDocument, pinDocument, unpinAllDocuments,
  } = useDocuments(props.folderId!.replace(/^new$/, ''));

  const params = useParams();
  const navigate = useNavigate();

  const isEmpty = !documents.length && !isPending;

  const documentDeletePopupState = useState(false);
  const [docToDelete, setDocToDelete] = useState<DocumentModel | null>(null);

  const openDocument = useCallback((docId: string) => navigate({
    name: 'document',
    params: {
      folderId: props.folderId!,
      documentId: docId,
    },
  }), [navigate, props.folderId]);

  const requestRemoveDocument = useCallback(async (doc: DocumentModel, confirm = false) => {
    if (!confirm) {
      documentDeletePopupState[1](true);
      setDocToDelete(doc);
      return;
    }

    await deleteDocument(doc.id);
    documentDeletePopupState[1](false);

    if (doc.id === params.documentId) {
      navigate({
        name: 'folder',
        params: { folderId: props.folderId! },
      });
    }
  }, [deleteDocument, documentDeletePopupState, params.documentId, navigate, props.folderId]);

  // NOTE: Opens first document in the list in case the documentId isn't found
  useEffect(() => {
    if (props.documentId || !documents.length || props.isPendingFolder) {
      return;
    }

    const [doc] = documents;

    if (doc.folderId === props.folderId) {
      openDocument(doc.id);
    }
  }, [documents, props, openDocument]);

  const query = props.searchQuery || '';
  const foundDocs = documents.filter(doc => {
    if (!query) return true;
    return doc.name.toLowerCase().includes(query.toLowerCase());
  }) 

  const getDocs = useCallback((pinned: boolean) => foundDocs
    .filter(doc => doc.isPinned === pinned)
    .map(doc => <DocumentButton
      key={doc.id}
      document={doc}
      onDeleteClick={ requestRemoveDocument }
      onPinClick={ doc => pinDocument(doc.id) }
    />), [foundDocs, requestRemoveDocument, pinDocument]);

  const skeletonDocuments = isPending && !documents.length && Array
    .from({ length: 5 })
    .map((_, i) => <DocumentButton skeleton document={{} as DocumentModel} key={i} />);

  return <>
    <OverlayScrollbarsComponent 
      className="docs-panel__list"
      options={{
        scrollbars: {
          autoHide: 'move',
        },
      }}
    >
      <DocumentGroup
        title="Pinned"
        actionTitle="Unpin all"
        onActionClick={ () => unpinAllDocuments(props.folderId!) }
        children={ getDocs(true) }
      />

      <DocumentGroup children={ getDocs(false) } />

      <DocumentGroup>
        { isEmpty && NO_DOCUMENTS_MESSAGE }
        { skeletonDocuments }
      </DocumentGroup>

      <ConfirmPopup
        title="Delete document"
        statePair={ documentDeletePopupState }
        message={ `Are you sure you want to delete the document "${docToDelete?.name}"` }
        onConfirm={ () => requestRemoveDocument(docToDelete!, true) }
        onCancel={ () => setDocToDelete(null) }
        isPending={ isPending }
        confirmText="Delete"
        danger
      />
    </OverlayScrollbarsComponent>
  </>
}
