import { useCallback, useEffect, useState } from "react"
import { ClarityDocsApi } from "..";
import { ClarityTeamEvent, Role } from "../services/team.service";
import { useTeam } from "./useTeam";

const session = new ClarityDocsApi();

export const useTeamMemberships = () => {
  const { team } = useTeam();
  const [isPending, setIsPending] = useState(false);
  const [memberships, setMemberships] = useState(team?.memberships || []);
  const [invitations, setInvitations] = useState(team?.invitations || []);

  const changeRole = useCallback((membershipId: string, role: Role) => {
    if (!team) return;
    setIsPending(true);
    session.team.changeRole(membershipId, role)
      .finally(() => setIsPending(false))
  }, [team]);

  useEffect(() => {
    session.team.getMemberships(team?.id!)
      .then(setMemberships);
    session.team.getInvitations(team?.id!)
      .then(setInvitations)
  }, [team]);

  useEffect(() => session
    .on(ClarityTeamEvent.TEAM_UPDATED, team => {
      setMemberships(team.memberships)
      setInvitations(team.invitations)
    }), []);

  return { 
    memberships,
    invitations,
    isPending,
    changeRole, 
  };
}
