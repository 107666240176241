import axios, { AxiosResponse } from "axios";

import { plainToInstance } from "class-transformer";
import { UserModel, UserUpdateModel, PasswordUpdateModel } from "./models/member.model";
import { Authorized, KeepPromise } from "./decorators";
import { MessageModel } from "./models/message.model";
import { SignUpModel } from "./models/signUp.model";
import { LoginResultModel } from "./models/loginResult.model";
import { LoginModel } from "./models/login.model";
import { pick } from "src/utils/objectUtils";
import { FoldersService } from "./services/folders.service";
import { DocumentsService } from "./services/document.service";
import { ChatService } from "./services/chat.service";
import { TeamService } from "./services/team.service";
import { PasswordResetValidationModel } from "./models/passwordResetValidation.model";
import { AuthStrategy } from "./strategies/types";
import { ImageModel } from "./models/image.model";

type SessionIndex = number;

export interface MessageReceiveEvent {
  folderId: string;
  message: MessageModel;
}

export enum ClarityDocsEvent {
  API_MESSAGE = 'api-message',
  API_ERROR_MESSAGE = 'api-error-message',

  UNAUTHORIZED = 'unauthorized',
  USER_UPDATED = 'user-updated',
  USER_MEMBERSHIP_CHANGED = 'user-membership-changed',
}


export class ClarityDocsApi {
  /**
   * Each instance of the ClarityDocsApi class is a separate user session
   */ 
  static sessions: ClarityDocsApi[] = [];
  static restored = false;

  static currentSession = 0;

  static tokens = new Proxy<Record<SessionIndex, string>>({}, {
    get(_, prop: string) {
      return localStorage.getItem(`clarity.token.${prop}`);
    },
    set(_, prop: string, value: string) {
      localStorage.setItem(`clarity.token.${prop}`, value);
      return true;
    }
  });

  static restoreSessions() {
    ClarityDocsApi.restored = true;

    Array
      .from({ length: localStorage.length })
      .forEach((_, i) => {
        if (!localStorage.key(i)?.startsWith('clarity.token.')) {
          return;
        }

        new ClarityDocsApi(true);
      });
  }

  public static warn(...message: any[]) {
    console.warn('[ClarityDocsApi]', ...message);
  }

  public static error(...message: any[]) {
    console.error('[ClarityDocsApi]', ...message);
  }

  public static log(...message: any[]) {
    console.info('[ClarityDocsApi]', ...message);
  }

  private client = (() => {
    const baseURL = process.env.NODE_ENV === 'production'
      ? window.location.origin.replace('app', 'api')
      : 'https://api.dev.claritydocs.ai';

    return axios.create({ baseURL });
  })();

  public user: UserModel | null = null;
  public sessionIndex = 0;

  public folders = new FoldersService(this);
  public documents = new DocumentsService(this);
  public chat = new ChatService(this);
  public team = new TeamService(this);


  constructor(newSession = false) {
    if (!ClarityDocsApi.restored) {
      ClarityDocsApi.restoreSessions();
    }

    if (!newSession) {
      const session = ClarityDocsApi.sessions[ClarityDocsApi.currentSession];

      if (session) {
        return session;
      }

      ClarityDocsApi.warn('No session found, creating a new one');
    }

    this.defineSession();
    this.defineInterceptors();
    this.refreshToken();
  }

  private get isTokenExpired() {
    if (!this.token) {
      return true;
    }

    const [, payload = ''] = this.token.split('.');

    if (!payload) {
      return true;
    }

    const { exp } = JSON.parse(atob(payload));

    return exp < Date.now() / 1000;
  }

  public get token() {
    return ClarityDocsApi.tokens[this.sessionIndex]
      || String(this.client.defaults.headers.Authorization || '').replace('Bearer ', '');
  }

  public set token(value: string) {
    ClarityDocsApi.tokens[this.sessionIndex] = value;
  }

  private defineSession() {
    ClarityDocsApi.sessions.push(this);
    this.sessionIndex = ClarityDocsApi.sessions.length - 1;
  }

  public dispatch<T = ClarityDocsEvent>(event: T, value?: any) {
    window.dispatchEvent(new CustomEvent('clarity:' + event, { detail: value }));
  }

  /**
   * Subscribes to an event/events and returns unsubscribe function
   */
  public on<T = ClarityDocsEvent>(event: T | T[], handler: (value: any) => void) {
    if (Array.isArray(event)) {
      const unsubs = event.map(e => this.on(e, handler) as Function);

      return () => {
        unsubs.forEach(unsub => unsub())
      };
    }

    const handlerWrapper = (e: CustomEvent) => handler(e.detail);

    window.addEventListener('clarity:' + event, handlerWrapper);

    return () => {
      window.removeEventListener('clarity:' + event, handlerWrapper);
    }
  }

  private async processLoginData(data: LoginResultModel, rememberMe: boolean) {
    if (!data?.token) {
      return false;
    }
    
    this.assignToken(data.token);

    await this.loadUser(data.user);

    if (rememberMe) {
      this.token = data.token;
    }

    return true;
  }

  /**
   * Logs in the user and stores the token in the instance
   */
  public async login(payload: LoginModel) {
    const body = pick(payload, ['email', 'password']);

    const { data } = (await this.client
      .post<LoginResultModel>('/account/login', body)) || {};

    return this.processLoginData(data, payload.rememberMe);
  }

  @KeepPromise()
  public async loginStrategy<T extends AuthStrategy<A>, A>(strategy: T) {
    const data = await strategy.authenticate(this.client);

    return this.processLoginData(data, true);
  }

  public async signUp(payload: SignUpModel) {
    const { data } = (await this.client
      .post<LoginResultModel>('/account/register', payload)) || {}

    if (!data?.token) {
      return false;
    }

    this.assignToken(data.token);
    await this.loadUser(data.user);
    
    this.token = data.token;

    return true;
  }

  public async deleteUser() {
    await this.client.delete('/user');
    await this.logout(true);
  }

  /**
   * Returns true if the user is logged in, false otherwise
   */
  public async isLoggedIn() {
    if (this.user) {
      return true;
    }

    if (!this.token) {
      return false;
    }

    try {
      if (!this.user) {
        await this.loadUser();
      }
      return true;
    } catch (e) {
      return false;
    }
  }

  public async updateUser(payload: UserUpdateModel) {
    await this.client.patch('/user', payload);

    this.user = this.user!.clone(payload);
    this.team.registerMember(this.user);
    this.dispatch(ClarityDocsEvent.USER_UPDATED, this.user);
    this.dispatch(ClarityDocsEvent.API_MESSAGE, 'Saved!');
  }

  @KeepPromise()
  public async confirmUser(token: string) {
    return this.client.patch('/confirmation/' + token, undefined, {
      headers: { noErrorToast: true },
    })
      .then(() => true)
      .catch(() => false);
  }

  public async updatePassword(payload: PasswordUpdateModel, resetToken?: string) {
    if (resetToken) {
      await this.client.patch(`/password-reset/${resetToken}`, {
        password: payload.password,
      });
      return;
    }

    await this.client.patch('/user/password', { password: payload.password });
    this.dispatch(ClarityDocsEvent.API_MESSAGE, 'Password updated');
  }

  public async resetPassword(email: string) {
    await this.client.post('/password-reset', { email });
  }

  @KeepPromise()
  public async validateResetToken(token: string) {
    const { data } = await this.client.get(`/password-reset/${token}`, {
      headers: { noErrorToast: true }
    });

    return plainToInstance(PasswordResetValidationModel, data, { excludeExtraneousValues: true });
  }

  @KeepPromise()
  @Authorized()
  public async loadUser(rawUser?: any, reloadStructures = true) {
    rawUser = rawUser || (await this.client.get('/user'))?.data;

    if (!rawUser) {
      return;
    }

    const inviteToken = window.localStorage.getItem('clarity.teamInviteToken');

    this.user = plainToInstance(UserModel, rawUser, { excludeExtraneousValues: true });
    this.team.registerMember(this.user);

    if (inviteToken) {
      await this.team.acceptInvitation(inviteToken)
        .catch(() => {})
        .finally(() => window.localStorage.removeItem('clarity.teamInviteToken'));
    }

    if (reloadStructures) {
      await this.loadStructures();
    }

    this.dispatch(ClarityDocsEvent.USER_UPDATED, this.user);
  }

  @KeepPromise()
  private async loadStructures() {
    return this.team.init()
        .then(() => this.folders.init())
        .then(() => this.chat.init());
  }


  @Authorized()
  public async logout(noRequest = false) {
    if (!noRequest) {
      await this.client.post('/account/logout');
    }

    delete this.client.defaults.headers.Authorization;
    this.token = '';
    this.user = null;
    this.dispatch(ClarityDocsEvent.UNAUTHORIZED);
  }

  public async updatePhoto(file: File) {
    if (!this.user) {
      return;
    }

    const formData = new FormData();
    formData.append('contents', file);

    const { data } = await this.client.patch<ImageModel>('/user/photo', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    });

    this.user = this.user.clone();
    this.user.image = data;
    this.team.registerMember(this.user);

    this.dispatch(ClarityDocsEvent.USER_UPDATED, this.user);
    this.dispatch(ClarityDocsEvent.API_MESSAGE, 'Photo updated');
  }

  private logMessageFromResponse(response: AxiosResponse<any, any>) {
    const { status, data = {} } = response;

    if (response.config?.headers.noErrorToast) {
      return;
    }

    if (!data.message) {
      return;
    }

    if (status >= 400) {
      this.dispatch(ClarityDocsEvent.API_ERROR_MESSAGE, data.message);
      return;
    }

    this.dispatch(ClarityDocsEvent.API_MESSAGE, data.message);
  }

  public handleRequestError(error: any) {
    error.response = error.response || { data: {} };
    const { response } = error;

    if (response.status === 401) {
      this.refreshToken();
    }

    this.logMessageFromResponse(response);

    return Promise.reject(error);
  }

  private defineInterceptors() {
    this.client.interceptors.response.use(r => r, (error) => {
      return this.handleRequestError(error);
    });
  }

  private assignToken(token: string) {
    this.token = token;
    this.client.defaults.headers.Authorization = `Bearer ${this.token}`;
  }

  private refreshToken() {
    if (!this.token || this.isTokenExpired) {
      this.token = '';
      this.dispatch(ClarityDocsEvent.UNAUTHORIZED);
      return;
    }

    this.assignToken(this.token);
  }

  public saveToLocalstorage(key: string, value: any) {
    localStorage.setItem(`clarity.${this.sessionIndex}.${key}`, JSON.stringify(value));
  }

  public getFromLocalStorage(key: string) {
    const value = localStorage.getItem(`clarity.${this.sessionIndex}.${key}`);

    try {
      return JSON.parse(value || '');
    } catch {
      return value;
    }
  }
}
