import clsx from "clsx";
import { useCallback, useEffect, useState } from "react";
import { ClarityDocsApi } from "src/api";
import { useTeam } from "src/api/hooks/useTeam";
import { SubscriptionPlanModel } from "src/api/models/subscriptionPlan.model";
import { Popup, PopupProps } from "src/components/ui";

const session = new ClarityDocsApi();

interface UpgradePlanPopupProps extends Omit<PopupProps, 'children'> {
  currentPlan: SubscriptionPlanModel;
}

export const UpgradePlanPopup = (props: UpgradePlanPopupProps) => {
  const { team } = useTeam();
  const [plans, setPlans] = useState<SubscriptionPlanModel[]>([]);
  const [isPending, setIsPending] = useState(false);

  const upgradePlan = useCallback((planId: string) => {
    if (!team) return;

    setIsPending(true);
    session.team.getCheckoutPlanUrl(team?.id, planId)
      .then(url => window.location.href = url)
      .finally(() => setIsPending(false));
  }, [team]);

  useEffect(() => {
    session.team.getPlans().then(setPlans);
  }, []);

  const cards = plans
    .filter(plan => plan.id !== props.currentPlan?.id)
    .map(plan => {
      return <div className="upgrade-plan__card" key={ plan.id }>
        <p 
          className="upgrade-plan__title"
          children={ plan.name }
        />

        <p
          className="upgrade-plan__price-label"
          children={ `$${plan.price}/month` }
        />

        <ul className="upgrade-plan__features-list">
          <li children={ `${plan.membersQuota} members` } />
          <li children={ `${plan.messagesMonthQuota} messages (month)` } />
          <li children={ `${plan.documentsQuota} documents` } />
        </ul>

        <button
          className={ clsx({
            "button button--primary": true,
            "button--pending": isPending,
          }) }
          children="Upgrade"
          onClick={ () => upgradePlan(plan.id) }
        />
      </div>
    })

  return <Popup
    title="Upgrade plan"
    width={ 810 }
    {...props}
  >
    <section className="upgrade-plan" children={ cards } />
  </Popup>
}
