import clsx from "clsx"
import { useCallback, useEffect, useRef, useState } from "react";
import { ClarityDocsApi } from "src/api";
import { useUser } from "src/api/hooks";
import { UserUpdateModel, PasswordUpdateModel } from "src/api/models/member.model";
import { Form, FormControls, TextField } from "src/components/ui";
import { getPayload, pick } from "src/utils/objectUtils";
import { rules } from "src/utils/validation";
import { AvatarUploader } from "../AvatarUploader";
import { ConfirmPopup } from "../ConfirmPopup";

const session = new ClarityDocsApi();

interface ProfileFormProps {
  onRequestClose: () => void;
}

export const ProfileForm = (props: ProfileFormProps) => {
  const { 
    user,
    updateUserInfo,
    updatePassword, 
    updatePhoto,
  } = useUser();

  const [isLoading, setLoading] = useState(true);
  const [isPending, setIsPending] = useState(false);
  const [isDeletingUser, setIsDeletingUser] = useState(false);
  const [passwordPending, setPasswordPending] = useState(false);
  const [newPassword, setNewPassword] = useState('');

  const deleteUserState = useState(false);
  const formControlRef = useRef<FormControls | null>(null);
  const passwordFormControlRef = useRef<FormControls | null>(null);

  const deleteUser = useCallback(() => {
    setIsDeletingUser(true);
    session.deleteUser()
      .finally(() => {
        setIsDeletingUser(false)
        deleteUserState[1](false);
      });
  }, [deleteUserState]);

  const saveSettings = useCallback((e: React.FormEvent<HTMLFormElement>) => {
    const payload = getPayload<UserUpdateModel>(e.currentTarget);

    if (!payload) return;

    setIsPending(true);
    updateUserInfo(payload).finally(() => setIsPending(false));
  }, [updateUserInfo, setIsPending]);

  const savePassword = useCallback((e: React.FormEvent<HTMLFormElement>) => {
    const { current: formControls } = passwordFormControlRef;

    if (!formControls) return;

    const payload = getPayload<PasswordUpdateModel>(e.currentTarget);
    delete payload.confirmNewPassword;

    setPasswordPending(true);
    updatePassword(payload)
      .catch(() => {})
      .finally(() => {
        formControls.clearForm();
        setPasswordPending(false);
      });
  }, [updatePassword, setPasswordPending]);

  // NOTE: Reload user data on popup open
  useEffect(() => {
    session.loadUser(null, false)
      .then(() => setLoading(false));
  }, []);

  // NOTE: Assign user data to the form
  useEffect(() => {
    const { current: formControls } = formControlRef;

    if (!formControls || !user) return;

    formControls.assignValues(pick(user, ['email', 'firstName', 'lastName', 'bio']));
  }, [user]);

  return <div className="profile-form flex flex--col flex--gap30">
    <div className={ clsx({
      "flex skeleton skeleton--light": true,
      "skeleton--active": isLoading,
    }) }>
      <div className="flex flex--col flex--gap30 flex-auto">
        <Form
          controls={ formControlRef }
          onSubmit={ saveSettings }
          className="flex flex--col flex--gap24"
          id="mainForm"
        >
          <TextField
            label="Email"
            name="email"
            placeholder="Enter your email"
            rules={[
              rules.required(),
              rules.email(),
            ]}
          />
  
          <div className="flex">
            <TextField
              label="First Name"
              name="firstName"
              placeholder="Enter your first name"
              className="flex-1 mr-2"
              rules={[
                rules.required(),
              ]}
            />
  
            <TextField
              label="Last Name"
              name="lastName"
              placeholder="Enter your last name"
              className="flex-1"
              rules={[
                rules.required(),
              ]}
            />
          </div>
  
          <TextField
            multiline
            name="bio"
            label="Bio"
            placeholder="Enter your bio, job title or any related information"
          />
        </Form>
  
        <hr />
  
        <Form
          onSubmit={ savePassword }
          className="flex flex--col"
          controls={ passwordFormControlRef }
        >
          <div className="flex">
            <TextField
              label="New password"
              placeholder="New password"
              name="password"
              password
              statePair={[newPassword, setNewPassword]}
              rules={[
                rules.required(),
                rules.password(),
              ]}
              className="flex-1 mr-2"
            />
  
            <TextField
              label="Repeat password"
              placeholder="Repeat password"
              name="confirmNewPassword"
              className="flex-1"
              password
              rules={[
                rules.required(),
                rules.equals('Passwords do not match', newPassword),
              ]}
            />
          </div>
  
          <div>
            <button
              type="submit"
              className={clsx({
                'button': true,
                'button--pending': passwordPending,
              })}
              children={ passwordPending ? 'Changing...' : 'Change password' }
            />
          </div>
        </Form>
      </div>
      <div>
        <AvatarUploader
          name="avatar"
          label="Photo" 
          src={ user?.image?.url }
          onChange={ updatePhoto }
          entityName={ user?.name }
        />
      </div>
    </div>
  
    <hr />
  
    <div className="flex flex--jbetween">
      <button 
        className="button button--danger"
        children="Delete account"
        onClick={ () => deleteUserState[1](true) }
      />
      <div className="flex">
        <button 
          className="button"
          onClick={ () => props.onRequestClose() }
          children="Cancel"
        />
  
        <input
          type="submit"
          value={ isPending ? "Saving..." : "Save changes" }
          form="mainForm"
          className={clsx({
            'button button--primary': true,
            'button--pending': isPending,
          })}
        />
      </div>
    </div>

    <ConfirmPopup
      statePair={ deleteUserState }
      title="Delete account"
      message="Are you sure you want to delete your account?"
      onConfirm={ deleteUser }
      isPending={ isDeletingUser }
      confirmText="Delete"
      danger
    />
  </div>
}
