import clsx from 'clsx';
import './index.scss';

interface AvatarProps {
  src?: string;
  alt?: string;
  size?: number;
  entityName?: string;
  appearance?: 'primary';
}

export const Avatar = (props: AvatarProps) => {
  const styles = {
    '--size': props.size,
  } as React.CSSProperties;

  if (!props.src) {
    const initials = (props.entityName || 'No Name')
      .split(' ')
      .slice(0, 2)
      .map(v => v[0])
      .join('');

    return <div 
      className={ clsx({
        'avatar': true,
        [`avatar--${props.appearance}`]: props.appearance,
      })}
      style={ styles }
      data-initials={ initials }
    />
  }

  return <img
    className={clsx({
      "avatar": true,
      "avatar--has-src": !!props.src,
    })}
    src={ props.src }
    alt={ props.alt  || ''}
    style={ styles }
  />
}
