import { useCallback, useEffect, useState } from "react";
import { ClarityDocsApi, ClarityDocsEvent } from "..";
import { LoginModel } from "../models/login.model";
import { UserModel, UserUpdateModel, PasswordUpdateModel } from "../models/member.model";
import { SignUpModel } from "../models/signUp.model";

const session = new ClarityDocsApi();

export const useUser = (userId?: string) => {
  const [isLoggedIn, setLoggedIn] = useState<boolean>(!!session.token);
  const [isPending, setIsPending] = useState(false);
  const [user, setUser] = useState(userId
    ? session.team.getMember(userId)
    : session.user);

  const updateUserInfo = useCallback(async (form: UserUpdateModel) => {
    setIsPending(true);
    return session.updateUser(form)
      .finally(() => setIsPending(false));
  }, []);

  const updatePassword = useCallback(async (payload: PasswordUpdateModel) => {
    setIsPending(true);
    return session.updatePassword(payload)
      .finally(() => setIsPending(false));
  }, []);

  // NOTE: Listen for user updates
  useEffect(() => session
    .on(ClarityDocsEvent.USER_UPDATED, (newUser: UserModel) => {
      if (newUser.id !== user?.id) return;
      setUser(newUser);
    }), [user]);
    
  // NOTE: Listen for unauthorized events
  useEffect(() => session
    .on(ClarityDocsEvent.UNAUTHORIZED, () => setLoggedIn(false)), []);

  const login = useCallback(async (payload: LoginModel) => {
    setIsPending(true);
    return session.login(payload)
      .then((res) => {
        setLoggedIn(true)
        return res;
      })
      .finally(() => setIsPending(false))
  }, []);

  const signUp = useCallback(async (payload: SignUpModel) => {
    setIsPending(true);
    return session.signUp(payload)
      .then((res) => {
        setLoggedIn(true)
        return res;
      })
      .finally(() => setIsPending(false))
  }, []);

  const updatePhoto = async (file: File) => {
    setIsPending(true);
    return session.updatePhoto(file)
      .finally(() => setIsPending(false));
  }

  return {
    isLoggedIn,
    isPending,
    user,
    session,
    login,
    signUp,
    updateUserInfo,
    updatePassword,
    updatePhoto,
  };
}
