import { Outlet, useParams } from "react-router-dom";
import { useCallback, useEffect } from 'react';
import { redirect, useNavigate } from 'src/router/routes';

import { ClarityDocsApi } from 'src/api';
import { useMembership, useUser } from 'src/api/hooks';

const session = new ClarityDocsApi();

export const Name = 'main';

export const Loader = async () => {

  if (!await session.isLoggedIn()) {
    return redirect({ name: 'signin' });
  }

  return null;
}

export const Page = () => {
  const { isLoggedIn } = useUser();
  const { notAllowedFor } = useMembership();
  const navigate = useNavigate();
  const params = useParams();

  const redirectToSetup = useCallback(async () => {
    const { teams } = session.team;
    const [folder] = session.folders.list;

    if (!teams.length) {
      navigate({ name: 'create-team' });
      return;
    }

    if (!folder && notAllowedFor(session.team.roles.USER)) {
      navigate({ name: 'create-folder' });
      return;
    }

    if (!folder) {
      return;
    }

    navigate({ name: 'folder', params: { folderId: folder.id } });
  }, [navigate, notAllowedFor]);

  // NOTE: Redirect to login page if user is not logged in
  useEffect(() => {
    if (!isLoggedIn) {
      navigate({ name: 'signin' });
      return;
    }

    if (!params.folderId) {
      redirectToSetup();
    }
  }, [isLoggedIn, navigate, redirectToSetup, params.folderId]);

  return <div>
    <Outlet />
  </div>
}
