import './index.scss';

import moment from 'moment';
import Markdown from 'react-markdown';
import clsx from 'clsx';
import RemarkGFM from 'remark-gfm';
import RemarkMath from 'remark-math';
import RehypeKatex from 'rehype-katex';

import { Avatar, Tooltip, TooltipPosition } from 'src/components/ui';
import { MessageModel } from 'src/api/models/message.model';
import { ClarityDocsApi } from 'src/api';
import { Footnote } from '../Footnote';
import { useUser } from 'src/api/hooks';

const session = new ClarityDocsApi();

export enum MessageType {
  OUTCOMING = 'outcoming',
  INCOMING = 'incoming',
}

interface MessageProps extends MessageModel {}

export const Message = (props: MessageProps) => {
  const { user } = useUser(props.userId);

  const entity = props.isFromAssistant
      ? session.chat.getAssistant(props.userId)
      : user!;

  const { name = props.userId } = entity || {};
  const icon = entity.image?.url;

  const type = props.userId === user?.id
    ? MessageType.OUTCOMING
    : MessageType.INCOMING;

  const copyMessage = () => {
    navigator.clipboard.writeText(props.content);
  }

  const classList = clsx({
    message: true,
    ['message--' + type]: true,
  });

  const footnotes = props.footnotes
    .map((footnote, index) => <Footnote key={ index } { ...footnote } />);
  
    return <div className={ classList }>
    <div className="message__header">
      <Avatar
        src={ icon }
        size={ 40 }
        entityName={ name }
      />
      <p className="message__name">
        { name }
      </p>

      <div className="message__meta">
        { moment(props.createdAt).format('DD MMM YYYY hh:mm A') }
      </div>
    </div>

    <div className="message__content">
      { props.attachment && <img
        src={ props.attachment.url }
        className="message__attachment"
        alt="Attachment"
        style={{
          aspectRatio: `${props.attachment.width || 1}/${props.attachment.height || 1}`,
        }}
      /> }
      <Markdown
        remarkPlugins={[ RemarkGFM, RemarkMath ]}
        rehypePlugins={[ RehypeKatex ]}
        children={ (props.content || '') }
      />

      { props.isProcessing && <div className="spinner"></div> }
    </div>

    { 
      !!footnotes.length && <div className="message__footnotes">
        { footnotes }
      </div>
    }

    { 
      !props.isProcessing 
      && <Tooltip content="Copy" position={ TooltipPosition.RIGHT }>
        <button className="message__copy-button" onClick={ copyMessage }> 
          <i className="icon-copy" />
        </button>
      </Tooltip> 
    }
  </div>;
}
