import './index.scss';
import clsx from 'clsx';
import { Document as PDFDocument, Page as PDFPage } from 'react-pdf';
import { useParams, useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useRef, useState } from 'react';
import { OverlayScrollbarsComponent, OverlayScrollbarsComponentRef } from 'overlayscrollbars-react';

import { useDocuments } from 'src/api/hooks';
import { Chat } from 'src/components/shared/Chat';
import { scrollToTarget } from 'src/utils/scrollToTarget';
import { DocumentToolbar } from 'src/components/shared/DocumentToolbar';

export const Name = 'document';

const DEFAULT_SCALE = 0.9;

export const Page = () => {
  const [query] = useSearchParams();

  const { folderId, documentId } = useParams();
  const { getDocument, isPending } = useDocuments(folderId!);

  const doc = getDocument(documentId!);
  const scrollRef = useRef<OverlayScrollbarsComponentRef | null>(null);

  const scalePair = useState(1);
  const screenshotPair = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageWidth, setPageWidth] = useState<number | undefined>(undefined);
  const [isScrolling, setIsScrolling] = useState(false);

  const handleResize = useCallback(() => {
    const pagesContainer = scrollRef.current?.getElement();

    if (!pagesContainer) {
      return;
    }

    const rect = pagesContainer.getBoundingClientRect();
    setPageWidth(rect.width * DEFAULT_SCALE);
  }, [scrollRef]);

  const switchPage = useCallback((page: number, instant = false) => {
    const pagesContainer = scrollRef.current?.getElement();

    if (!pagesContainer) {
      return;
    }

    const pageElement = pagesContainer.querySelector(`[data-page-number="${page + 1}"]`);
    if (!pageElement) {
      return;
    }

    const scrollContainer = pagesContainer.children[1] as HTMLDivElement;

    setIsScrolling(true);
    setCurrentPage(page);
    scrollToTarget(scrollContainer, pageElement, instant)
      .then(() => {
        setIsScrolling(false);
      })
      .catch(() => {});
  }, [scrollRef]);

  const onLoad = useCallback(() => {
    const page = query.get('page');
    if (page) {
      switchPage(+page - 1, true);
    }
  }, [query, switchPage]);

  const onPagesScroll = useCallback((_: any, e: Event) => {
    const { target } = e as unknown as { target: HTMLDivElement };

    const pagesContainer = scrollRef.current?.getElement();

    if (!pagesContainer) {
      return;
    }

    const pages = target.querySelectorAll('.document-view__page');

    const newPage = Array.from(pages).findIndex((page) => {
      const rect = page.getBoundingClientRect();

      return rect.top >= 0 && rect.top <= pagesContainer.clientHeight / 2;
    });

    if (isScrolling) {
      return;
    }

    if (newPage !== -1 && newPage !== currentPage) {
      setCurrentPage(newPage);
    }
  }, [currentPage, isScrolling]);

  // NOTE: adapt the page width to the container width once the window is resized
  useEffect(() => {
    window.addEventListener('resize', handleResize);

    setTimeout(() => {
      handleResize();
    }, 100);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, [handleResize]);

  // NOTE: scroll to the page if the page number is provided in the query
  useEffect(() => {
    if (!doc) {
      return;
    }

    const page = query.get('page');
    if (page) {
      switchPage(+page - 1, true);
    }
  }, [query, doc, switchPage]);

  // NOTE: set the scale to 0.1 if it's 0
  useEffect(() => {
    const [scale, setScale] = scalePair;

    if (scale === 0) {
      setScale(0.1);
    }
  }, [scalePair]);

  if (!doc && !isPending) {
    return <div className="document-view">
      <p className="document-view__error">
        <i className="icon-cross"></i>
        The doc is not found
      </p>
    </div>
  }

  if (!doc) {
    return null;
  }

  const pages = Array
    .from({ length: doc.pageCount }, (_, i) => <PDFPage
      key={ i }
      className={clsx({
        "document-view__page": true,
        "document-view__page--active": i === currentPage,
      })}
      pageNumber={ i + 1 }
      width={ pageWidth }
      onRenderSuccess={ () => { !i && onLoad() } }
      scale={ scalePair[0] }
    />);

    return <div className="document-view">
      <section className="document-view__main">
        <DocumentToolbar 
          doc={ doc }
          scalePair={ scalePair }
          currentPage={ currentPage }
          onScreenshotTaken={ d => screenshotPair[1](d) }
        />
        <PDFDocument
          className="document-view__viewer"
          loading={() => <div className="document-view__spinner"><div className="spinner" /></div>}
          file={ doc.url }
          onLoadSuccess={ handleResize }
        >
          <OverlayScrollbarsComponent
            className={clsx({
              "document-view__scroll": true,
              "document-view__scroll--over100": scalePair[0] * DEFAULT_SCALE > DEFAULT_SCALE,
            })}

            options={{
              scrollbars: {
                autoHide: 'move',
              },
            }}

            events={{
              scroll: onPagesScroll as any,
            }}
            
            ref={ scrollRef }
          >
            <div className="simplebar-content">
              { pages }
            </div>
          </OverlayScrollbarsComponent>
        </PDFDocument>
      </section>
      <Chat
        documentId={ documentId! }
        folderId={ folderId! }
        screenshot={ screenshotPair[0] }
        onResetScreenshot={ () => screenshotPair[1](null) }
      />
    </div>;
}
