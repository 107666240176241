import { AxiosInstance } from "axios";
import { plainToInstance } from "class-transformer";
import { LoginResultModel } from "../models/loginResult.model";
import { AuthStrategy } from "./types";

interface GoogleAuthStrategyParams {
  code: string;
}

export class GoogleAuthStrategy extends AuthStrategy<GoogleAuthStrategyParams> {
  public async authenticate(client: AxiosInstance) {
    const { data } = await client.post('/account/google', {
      code: this.params.code,
    });

    return plainToInstance(LoginResultModel, data);
  }
}
