import { Expose } from "class-transformer";
import { FileModel } from "./file.model";

export class ImageModel extends FileModel {
  @Expose()
  width?: number;

  @Expose()
  height?: number;
}
