import moment from "moment";
import { useState } from "react";
import { ClarityDocsApi } from "src/api";
import { useTeamMemberships } from "src/api/hooks/useTeamMemberships";
import { InvitationModel } from "src/api/models/invitation.model";
import { InvitationDeleteConfirmPopup } from "./InvitationDeleteConfirmPopup";

interface InvitationListProps {
  query: string;
}

const session = new ClarityDocsApi();

export const InvitationList = (props: InvitationListProps) => {
  const { invitations } = useTeamMemberships();
  const deleteConfirmState = useState(false);
  const [invitationToRevoke, setInvitationToRevoke] = useState<InvitationModel | null>(null);

  const headers = [
    'Name', 'Role', 'Invited', 'Action',
  ].map(title => <th key={ title } children={ title } />);

  const rows = invitations
    .filter(invitation => invitation.email
      .toLowerCase().includes(props.query.toLowerCase()))
    .map((invitation) => {
      const revokeTrigger = <button
        className="button button--link"
        children="Revoke" 
        onClick={ () => {
          setInvitationToRevoke(invitation);
          deleteConfirmState[1](true);
        } }
      />

      return <tr key={ invitation.id }>
        <td children={ invitation.email }/>
        <td width="24%" children={ session.team.getRoleLabel(invitation.role) }/>
        <td width="15%" children={ moment(invitation.createdAt).format('DD MMM YYYY') }/>
        <td width="10%" children={ revokeTrigger } />
      </tr>
    });

    return <>
      <table className="table" cellSpacing={0} cellPadding={0}>
        <thead>
          <tr children={ headers } />
        </thead>
        <tbody>
          { rows }
          { rows.length < 1 && <tr>
            <td colSpan={headers.length} children="No invitations found" /> 
          </tr> }
        </tbody>
      </table>

      <InvitationDeleteConfirmPopup
        invitation={ invitationToRevoke! }
        statePair={ deleteConfirmState }
      />
    </>
}
