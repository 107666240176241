import './index.scss';

import clsx from "clsx"
import { useEffect, useRef, useState } from 'react';

interface TabsProps {
  statePair?: [number, (index: number) => void];
  children?: React.ReactElement[] | React.ReactElement;
  tabs: string[];
  className?: string;
}

export const Tabs = (props: TabsProps) => {
  const tabState = useState(0);
  const tabsContainer = useRef<HTMLDivElement | null>(null);
  const [tabParam, setTabParam] = useState<{ left: number, width: number }>({
    left: 0,
    width: 0,
  });

  const [currentTab, setCurrentTab] = props.statePair || tabState;
  const children = Array.isArray(props.children) ? props.children : [props.children]

  const tabButtons = props.tabs.map((tab, index) => (
    <button
      key={ index }
      onClick={ () => setCurrentTab(index) }
      className={ clsx({
        "tabs__button": true,
        "tabs__button--active": index === currentTab
      }) } 
    >
      { tab }
    </button>
  ));

  useEffect(() => {
    const { current } = tabsContainer;
    if (!current) return;

    const el = current.children[currentTab] as HTMLElement;
    if (!el) return;

    setTabParam({
      left: el.offsetLeft,
      width: el.offsetWidth,
    });
  }, [currentTab]);

  return <div 
    className={ clsx('tabs', props.className || '') }
  >
    <div
      className="tabs__buttons"
      ref={ tabsContainer }
      style={{
        '--selected-left': `${tabParam.left}px`,
        '--selected-width': `${tabParam.width}px`
      } as React.CSSProperties }
    >
      { tabButtons }
    </div>

    <div className="tabs__content">
      { children[currentTab] }
    </div>
  </div>
}
