import { useState } from "react";
import { CreateTeamPopup } from "src/components/shared/CreateTeamPopup";

export const Name = 'create-team';

export const Page = () => {
  const createPopupState = useState(true);

  return <div>
    <CreateTeamPopup 
      statePair={ createPopupState }
      persistentMode
    />
  </div>
}


