import { ClarityDocsApi } from ".";

/**
 * Decorator that ensures the method is only called if the user is authorized
 */
export function Authorized(unauthorizedReturn: any = null) {
  return function(_: any, __: string, descriptor: PropertyDescriptor) {
    const originalMethod = descriptor.value!;

    descriptor.value = async function(...args: any[]) {
      const instance = this as ClarityDocsApi;

      if (!instance.token) {
        ClarityDocsApi.warn('Unauthorized request');
        return unauthorizedReturn;
      }

      return originalMethod.apply(this, args);
    }
  }
}

/**
 * Decorator that returns the same promise if the arguments are the same till the promise resolves
 */
export function KeepPromise() {
  return function(_: any, methodName: string, descriptor: PropertyDescriptor) {
    const originalMethod = descriptor.value!;

    descriptor.value = async function(...args: any[]) {
      const hash = JSON.stringify(args) + '_' + methodName;
      const instance = this as { cache?: Record<string, any> };

      if (!instance.cache) {
        instance.cache = {};
      }

      instance.cache[hash] = instance.cache[hash] || originalMethod.apply(this, args);

      instance.cache[hash].then((r: any) => {
        delete instance.cache![hash];
        return r;
      });

      return instance.cache[hash];
    }
  }
}
