import clsx from "clsx";
import { useCallback, useEffect, useState } from "react";
import { DocumentModel } from "src/api/models/document.model";
import { Dropdown, DropdownButton, DropdownHeader, TextField, Tooltip, TooltipPosition } from "src/components/ui";
import { ScreenshootProcessor, ScreenshootProcessorEvents, SelectionData, SelectionResult } from "src/utils/screenshootProcessor";

interface DocumentToolbarProps {
  doc: DocumentModel;
  currentPage: number;
  scalePair: [number, (value: number) => void];
  onScreenshotTaken: (base64: string | null, selection: SelectionData) => void;
}

const doScreenshotAnimation = (base64: string, selection: SelectionData, callback: () => void) => {
  const targetElement = document.querySelector('#chat-attachment') as HTMLButtonElement;
  const targetBbox = targetElement.getBoundingClientRect();

  const el = document.createElement('img');
  el.className = "chat__attachment-preview"
  el.src = base64;
  el.style.left = selection.startX + 'px';
  el.style.top = selection.startY + 'px';
  el.style.width = Math.abs(selection.endX - selection.startX) + 'px';
  el.style.height = Math.abs(selection.endY - selection.startY) + 'px';

  el.addEventListener('load', () => {
    el.style.left = targetBbox.left + 'px';
    el.style.top = targetBbox.top + 'px';
    el.style.width = targetBbox.width + 'px';
    el.style.height = targetBbox.height + 'px';

    el.addEventListener('transitionend', () => {
      setTimeout(() => el.parentElement?.removeChild(el));
      callback();
    }, { once: true });

  }, { once: true });

  document.body.appendChild(el);
}

export const DocumentToolbar = (props: DocumentToolbarProps) => {
  const { doc, currentPage, scalePair } = props;
  const [scale, setScale] = scalePair;
  const [scrProcessor, setScrProcessor] = useState<ScreenshootProcessor | null>(null);
  const [selection, setSelection] = useState<SelectionData | null>(null);

  const beginScreenshotMode = useCallback(() => {
    if (scrProcessor) return;

    setSelection(null);
    window.dispatchEvent(new CustomEvent("screenshot-mode:start"));

    const processor = new ScreenshootProcessor();

    const unsubUpdate = processor.on<SelectionData>(
      ScreenshootProcessorEvents.UPDATE_SELECTION, 
      (selection) => { setSelection(selection) },
    );

    const unsubEnd = processor.on<SelectionResult>(
      ScreenshootProcessorEvents.END_SELECTION, 
      ({ base64, selection }) => {
        processor.clear();
        window.dispatchEvent(new CustomEvent("screenshot-mode:end"));

        if (base64) {
          doScreenshotAnimation(base64, selection, () => {
            props.onScreenshotTaken(base64, selection);
            setScrProcessor(null);
          });
        } else {
          setScrProcessor(null);
        }

        setSelection(null);
        unsubUpdate();
        unsubEnd();
      },
    );

    setScrProcessor(processor);
  }, [scrProcessor, props]);

  // NOTE: clear the screenshot processor when the component is unmounted
  useEffect(() => () => {
    if (scrProcessor) scrProcessor.clear();
  })

  return <>
    { selection && scrProcessor && <div
      className="document-view__selection-box"
      style={{
        left: selection.startX,
        top: selection.startY,
        width: selection.endX - selection.startX,
        height: selection.endY - selection.startY,
      }}
    /> }
    <header className="document-view__header">
      <h2 className="tx-section-heading" title={ doc.name }>
        <span children={ doc.name } />
      </h2>

      <div className="document-view__actions">
        <button 
          className="button button--tool"
          disabled
        >
          { currentPage + 1 }
        </button>

        <div className="document-view__divider" />

        <Tooltip content="Capture area" position={ TooltipPosition.BOTTOM }>
          <button 
            className={ clsx({
              "button button--tool-icon": true,
              "button--active": !!scrProcessor,
            })}
            onClick={ beginScreenshotMode }
          >
            <i className="icon-mouse-square" />
          </button>
        </Tooltip>

        <div className="document-view__divider" />

        <Tooltip content="Zoom in" position={ TooltipPosition.BOTTOM }>
          <button 
            className="button button--tool-icon"
            onClick={ () => setScale(scale + 0.1) }
          >
            <i className="icon-zoom-in"></i>
          </button>
        </Tooltip>

        <Tooltip content="Zoom out" position={ TooltipPosition.BOTTOM }>
          <button
            className="button button--tool-icon"
            onClick={ () => setScale(scale - 0.1) }
          >
            <i className="icon-zoom-out"></i>
          </button>
        </Tooltip>

        <Dropdown
          tooltip="Zoom"
          tooltipPosition={ TooltipPosition.BOTTOM }
          activator={
            <button
              className="document-view__zoom-button button button--tool"
            >
              { (scale * 100).toFixed(0) + '%' }
            </button>
          }
          vside="bottom"
          hside="right"
          width={ 100 }
        >
          <DropdownHeader>
            <TextField
              triggerByBlur
              type="number"
              statePair={[
                (scale * 100) >> 0,
                (value: string) => setScale(+value / 100)
              ]}
            />
          </DropdownHeader>

          <DropdownButton
            onClick={ () => setScale(0.5) }
            label="50%"
          />

          <DropdownButton
            onClick={ () => setScale(1) }
            label="100%"
          />

          <DropdownButton 
            onClick={ () => setScale(2) }
            label="200%"
          />
        </Dropdown>
      </div>
    </header>
  </>
}
