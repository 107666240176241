import './index.scss';
import { createPortal } from "react-dom";
import { useCallback, useEffect, useRef } from 'react';
import clsx from 'clsx';

export interface PopupProps {
  title?: string;
  children: React.ReactNode;
  width?: number;
  statePair: [any, React.Dispatch<React.SetStateAction<any>>];
  onClose?: () => void;
  persistent?: boolean;
  noOverlay?: boolean;
  noShadow?: boolean;
}

export const Popup = (props: PopupProps) => {
  const windowRef = useRef<HTMLDivElement>(null);

  const styles = {
    '--width': props.width,
  } as React.CSSProperties;

  const close = useCallback(() => {
    props.statePair[1](false);
    if (props.onClose) {
      props.onClose();
    }
  }, [props]);

  const handleOverlayClick = useCallback((e: React.MouseEvent) => {
    if (props.persistent) {
      return;
    }

    if (e.target === e.currentTarget) {
      close();
    }
  }, [close, props.persistent]);

  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && props.statePair[0]) {
        close();
      }
    }

    document.addEventListener('keyup', handleEscape);
    return () => document.removeEventListener('keyup', handleEscape);
  }, [close, props.statePair]);

  useEffect(() => {
    const { current: windowElement } = windowRef;
    if (!windowElement) {
      return;
    }

    const updateHeight = () => {
      windowElement.style.maxHeight = `0px`;
      windowElement.style.height = windowElement.scrollHeight + 'px';
      windowElement.style.maxHeight = ``;
    }

    const mo = new MutationObserver(updateHeight);

    updateHeight();

    mo.observe(windowElement, {
      childList: true,
      subtree: true,
    });

    return () => mo.disconnect();
  }, [props.statePair, props.children]);

  if (!props.statePair[0]) {
    return null;
  }

  return createPortal(
    <div
      className={clsx({
        'popup': true,
        'popup--no-overlay': props.noOverlay,
        'popup--no-shadow': props.noShadow,
      })}
      style={ styles }
      onClick={ handleOverlayClick }
    >
      <div className="popup__window block" ref={ windowRef }>
        <header className="popup__header">
          { props.title && <h2 className="popup__heading" children={ props.title } /> }

          { !props.persistent && <button className="popup__close-button" onClick={close}>
            <i className="icon-cross"></i>
          </button> }
        </header>

        <div className="popup__content">
          { props.children }
        </div>
      </div>
    </div>, 

    document.body
  );
}
