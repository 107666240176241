const OBJECT_START = "{";
const OBJECT_END = "}";
const QUOTE_CHAR = "\"";

type CallbackFunc<T> = (result: T) => void;

/**
 * Provides a way to decode JSON objects from a stream of JSON data.
 */
export class JsonDecoder {
  level = 0;
  itemStart = 0;
  partialItem = "";
  array = true;
  decoder = new TextDecoder();
  isObjectStarted = false;
  isStringStarted = false;

  private onStart() {
    this.itemStart = 0;
  }

  private onObjectStart(idx: number, _: string) {
    if (this.level === 0) {
      this.itemStart = idx;
    }
    this.level++;
    this.isObjectStarted = true;
  }

  private onObjectEnd<T>(idx: number, chunk: string, callback: CallbackFunc<T>) {
    this.level--;
    if (this.level === 0) {
      let item = chunk.substring(this.itemStart, idx + 1);
      const hasPartialItem = this.partialItem.length > 0;

      if (hasPartialItem) {
        item = this.partialItem + item;
        this.partialItem = "";
      }

      try {
        callback(JSON.parse(item));
      } catch (e) {
        console.log("Chunk decoding error:");
        console.log("IDX: " + idx);
        console.log("CHUNK: " + chunk);
        console.log("ITEM: " + item);
        throw e;
      }

      this.isObjectStarted = false;
    }
  }

  private onPartial(chunk: string) {
    this.partialItem = chunk.substring(this.itemStart);
  }

  private onEnd() {
    this.itemStart = 0;
  }

  decode<T>(value: Buffer, callback: CallbackFunc<T>) {
    this.onStart();
    const chunk = this.decoder.decode(value);

    for (let idx = 0; idx < chunk.length; idx++) {
      switch (chunk[idx]) {
        case QUOTE_CHAR:
          if (this.isObjectStarted) this.isStringStarted = !this.isStringStarted;
          break;

        case OBJECT_START:
          if (this.isStringStarted) break;

          this.onObjectStart(idx, chunk);
          break;

        case OBJECT_END:
          if (this.isStringStarted || !this.isObjectStarted) break;

          this.onObjectEnd<T>(idx, chunk, callback);
          break;

        default: break;
      }
    }

    if (this.level !== 0) {
      this.onPartial(chunk);
    }

    this.onEnd();
  }
}

// @ts-ignore
window.JSONDecoder = new JsonDecoder();
