/**
 * Returns a new object with the selected keys from the original object.
 */
export const pick = <T, K extends keyof T>(obj: T, keys: K[]): Pick<T, K> => {
  const result = {} as Pick<T, K>;
  keys.forEach((key) => {
    result[key] = obj[key];
  });
  return result;
}

/**
 * Returns an object with values from the specified form element.
 */
export const getPayload = <T extends Object = any>(form: HTMLFormElement): T => {
  const elements = form.elements as any;
  const payload = {} as T;

  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    if (!element.name) continue;

    const value = element.type === 'checkbox'
      ? element.checked
      : +element.value || element.value;

    if (value === "") continue;

    payload[element.name as keyof T] = value;
  }

  return payload;
}

/**
 * Merges react refs into a single ref.
 */
export const mergeRefs = (...refs: React.MutableRefObject<any>[]) => {
  return (node: any) => {
    for (const ref of refs) {
      if (!ref) continue;
      ref.current = node;
    }
  }
}
