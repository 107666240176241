import clsx from "clsx";
import { FormEventHandler, MutableRefObject, useCallback, useEffect, useRef, useState } from "react";
import { Validateable } from "src/utils/validation";
import './index.scss';

export interface TextFieldProps extends Validateable {
  label?: string;
  statePair?: [string | number, React.Dispatch<React.SetStateAction<string | number>>];
  placeholder?: string;
  password?: boolean;
  name?: string;
  multiline?: boolean;
  className?: string;
  pending?: boolean;
  disabled?: boolean;
  inputRef?: MutableRefObject<HTMLInputElement | HTMLTextAreaElement | null>;
  type?: 'text' | 'password' | 'email' | 'number';
  triggerByBlur?: boolean;
  noBorder?: boolean;
  appearance?: 'filled';
  maxLength?: number;

  prependIcon?: string;
  appendIcon?: string;

  onInput?: (value: string) => void;

  onAppendClick?: () => void;
  onPrependClick?: () => void;
};

export const TextField = (props: TextFieldProps) => {
  const [value, setValue] = useState(props.statePair ? props.statePair[0] : '');
  const [errors, setErrors] = useState<string[]>([]);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const currentValue = props.statePair && !props.triggerByBlur
    ? props.statePair[0]
    : value;

  const onInput: FormEventHandler<HTMLInputElement | HTMLTextAreaElement> = useCallback((e) => {
    setErrors([]);

    const notByBlur = props.triggerByBlur && e.type !== 'blur';
    const value = (e.target as HTMLInputElement).value;

    props.onInput?.(value);

    if (!props.statePair || notByBlur) {
      setValue(value);
      return;
    }


    props.statePair[1](value);
    props.onInput?.(value);
  }, [props]);

  const onKeyUp = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Enter' && props.triggerByBlur) {
      props.statePair?.[1](value);
    }
  }, [props, value]);

  const validate = useCallback((form: Record<string, string>) => {
    if (!props.rules) return;

    const errors = props.rules
      .map(rule => rule(String(currentValue), form))
      .filter(Boolean);

    setErrors(errors as string[]);

    return errors;
  }, [props.rules, currentValue]);

  const assignValue = useCallback((value: string) => {
    setValue(value);

    if (props.statePair) {
      props.statePair[1](value);
    }
  }, [props]);

  useEffect(() => {
    const { current: wrapper } = wrapperRef;
    (wrapper?.children[0] as any)._validate = validate;
    (wrapper?.children[0] as any)._assignValue = assignValue;
  }, [validate, assignValue]);

  const classList = clsx({
    'text-field field': true,
    'text-field--error': errors.length > 0,
    'text-field--disabled': props.disabled,
    'text-field--pending': props.pending,
    'text-field--has-append': !!props.appendIcon,
    [`text-field--${props.appearance}`]: !!props.appearance,
    [props.className || '']: true,
  });

  const [showPassword, setShowPassword] = useState(false);
  const passwordIcon = showPassword ? 'icon-eye-off' : 'icon-eye';
  const togglePasswordShow = () => setShowPassword(!showPassword);
  const passwordType = showPassword ? 'text' : 'password';
  const type = props.password ? passwordType : props.type || 'text';

  const inputProps = {
    value: currentValue,
    disabled: props.disabled,
    placeholder: props.placeholder,
    onInput: onInput,
    onBlur: onInput,
    onKeyUp: onKeyUp,
    ref: props.inputRef,
    className: clsx({
      'text-field__input': true,
      'text-field__input--noborder': props.noBorder,
    }),
    type,
    name: props.name,
    maxLength: props.maxLength,
  }

  return <div className={classList}>
    {
      props.label
        ? <label className="field__label">
          { props.label }
        </label>
        : null
    }

    <div className="text-field__input-wrapper" ref={ wrapperRef }>
      { props.prependIcon && <button 
        onClick={ props.onPrependClick }
        className="text-field__icon text-field__icon--prepend"
        children={ <i className={ `icon-${ props.prependIcon }` } /> } 
      /> }
      { 
        !props.multiline
          ? <input {...inputProps as any }/>
          : <textarea { ...inputProps as any } />
      }

      {
        props.password
          ? (
            <button
              className="text-field__control-button"
              type="button"
              onClick={ togglePasswordShow }
              tabIndex={-1}
            >
              <i className={ passwordIcon } />
            </button>
          )
          : null
      }

      { props.appendIcon && <button 
        onClick={ props.onAppendClick }
        className="text-field__icon text-field__icon--append"
        children={ <i className={ `icon-${ props.appendIcon }` } /> } 
      /> }
    </div>

    { !!errors.length && <p className="text-field__error-label tx-small">
      { errors.join(', ') }
    </p> }
  </div>;
}
