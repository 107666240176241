import { Expose, Type } from "class-transformer";
import { Role } from "../services/team.service";
import { UserModel } from "./member.model";

export class InvitationModel {
  @Expose()
  public id: string;

  @Expose()
  public email: string;

  @Expose()
  public teamId: string;

  @Expose({ name: 'roleId' })
  public role: Role;

  @Expose()
  public isUsed: boolean;

  @Expose()
  @Type(() => Date)
  public expiresAt: Date;

  @Expose()
  @Type(() => UserModel)
  public createdBy: UserModel;

  @Expose()
  @Type(() => Date)
  public createdAt: Date;
}
