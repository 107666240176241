import { useCallback, useEffect, useState } from "react";
import { ClarityDocsApi } from "../";
import { ClarityTeamEvent, Role } from "../services/team.service";

const session = new ClarityDocsApi();

export const useMembership = () => {
  const [membership, setMembership] = useState(session.team.currentMembership);
  const [memberships, setMemberships] = useState(session.team.memberships);

  useEffect(() => session.on(ClarityTeamEvent.USER_MEMBERSHIP_CHANGED, setMembership), []);
  useEffect(() => session.on(ClarityTeamEvent.MEMBERSHIPS_UPDATED, setMemberships), []);

  /**
   * Check if the user is not allowed for the given roles
   */
  const notAllowedFor = useCallback((...roles: (Role | undefined)[]) => !roles.includes(membership?.role), [membership]);

  /**
   * Check if the user is allowed for the given roles
   */
  const allowedFor = useCallback((...roles: (Role | undefined)[]) => roles.includes(membership?.role), [membership]);

  return {
    notAllowedFor,
    allowedFor,

    /**
     * User's current membership
     */
    membership,

    /**
     * User's memberships
     */
    memberships,
  };
}
