import { ClassConstructor } from "class-transformer";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { ClarityDocsApi } from "src/api";
import { useNavigate } from "src/router/routes";
import { GoogleAuthStrategy } from "../../../api/strategies/google.strategy";
import { AuthStrategy } from "../../../api/strategies/types";

const session = new ClarityDocsApi();

const STRATEGIES: Record<string, ClassConstructor<AuthStrategy<any>>> = {
  'google': GoogleAuthStrategy,
}

export const Page = () => {
  const navigate = useNavigate();
  const [isPending, setIsPending] = useState(false);
  const { provider } = useParams<{ provider: string }>();
  const [query] = useSearchParams();

  useEffect(() => {
    if (!provider || isPending) {
      return;
    }

    setIsPending(true);

    const params = Object.fromEntries(query.entries());
    const strategy = new STRATEGIES[provider](params);

    session.loginStrategy(strategy).then((success) => {
      if (!success) {
        setIsPending(false);
        return;
      }

      navigate({ name: 'main' });
    });
  }, [provider, query, isPending, navigate]);

  return null;
}
