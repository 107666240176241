import { cancelTimeout, easeOutQuad, setTimeoutProgress } from "./setTimeoutProgress";

let currentTimeout: Promise<void> | null = null;

const timeouts: Map<Element, Promise<void>> = new Map();

export const scrollToTarget = (scrollContainer: Element, target: Element, instant = false) => {
  const measureWrapper = scrollContainer.parentElement!.getBoundingClientRect();
  const rect = target.getBoundingClientRect();
  const startPos = scrollContainer.scrollTop;

  const targetPosition = (rect.top - measureWrapper.top) - scrollContainer.clientHeight / 2 + rect.height / 2;

  if (instant) {
    scrollContainer.scrollTop = startPos + targetPosition;
    return Promise.resolve();
  }

  if (timeouts.has(scrollContainer)) {
    cancelTimeout(timeouts.get(scrollContainer)!);
  }

  currentTimeout = setTimeoutProgress((progress: number) => {
    scrollContainer.scrollTop = startPos + targetPosition * easeOutQuad(progress);
  }, 500);

  timeouts.set(scrollContainer, currentTimeout);

  return currentTimeout;
}
