import { useGoogleLogin } from '@react-oauth/google';
import GOOGLE_LOGO from 'src/assets/images/google-logo.svg';

interface GoogleSignInProps {
  label: string;
  disabled?: boolean;
}

export const GoogleSignInButton = (props: GoogleSignInProps ) => {
  const redirect_uri = window.location.hostname.startsWith('localhost')
    ? 'https://app.dev.claritydocs.ai/auth/google/callback'
    : window.location.origin + '/auth/google/callback';

  const login = useGoogleLogin({
    ux_mode: 'redirect',
    redirect_uri,

    onError: (error) => {
      console.error('Google login error:', error);
    },
    flow: 'auth-code',
  })

  return <button 
    type="button" 
    className="button button--gray"
    onClick={ () => login() }
    disabled={ props.disabled }
  >
      <img src={ GOOGLE_LOGO } alt="Google" />
      { props.label }
  </button>;
}
