import { ArtworkLayout } from "src/components/layouts/ArtworkLayout";

import { Form, TextField } from "src/components/ui";
import { useState } from "react";

import IMAGE_ARTWORK from 'src/assets/images/artwork.jpg';
import ENVELOPE_IMAGE from 'src/assets/images/envelope.svg';
import { ClarityDocsApi } from "src/api";
import { getPayload } from "src/utils/objectUtils";
import clsx from "clsx";
import { Outlet, useParams } from "react-router-dom";

const session = new ClarityDocsApi();

export const Page = () => {
  const params = useParams<{ code: string }>();

  const [isPending, setIsPending] = useState(false);
  const [isSent, setIsSent] = useState(false);

  const resetPassword: React.FormEventHandler = (e) => {
    const payload = getPayload<{ email: string }>(e.currentTarget as HTMLFormElement);

    setIsPending(true);
    session.resetPassword(payload.email)
      .then(() => setIsSent(true))
      .finally(() => setIsPending(false))
  };

  const formStage = <>
    <h1 className="tx-center tx-section-heading">
      Reset password 
    </h1>

    <p className="tx-center txc-label">
      Please enter your email address, and we will send you a password reset link
    </p>

    <Form 
      className="flex flex--col flex--gap24" 
      onSubmit={ resetPassword }
    >
      <TextField 
        name="email" 
        label="Email" 
        placeholder="Enter email address"
      />

      <button
        className={clsx({
          "button button--primary button--fluid": true,
          "button--pending": isPending,
        })}
      >
        Send reset link
      </button>
    </Form>
  </>

  const successStage = <>
    <div>
      <img src={ ENVELOPE_IMAGE } alt="Envelope" width="100" />
    </div>

    <h1 className="tx-center tx-section-heading">
      Check your inbox
    </h1>

    <p className="tx-center txc-label">
      Go to your email and follow the password reset instructions you got there
    </p>

    <div>
      <button
        className="button button--gray"
        onClick={ () => setIsSent(false) }
      >
        Try again
      </button>
    </div>
  </>;

  return <ArtworkLayout imageSrc={ IMAGE_ARTWORK }>
    { 
      !params.code 
      ? <div 
        className="flex flex--col flex--gap30 tx-center"
        children={ isSent ? successStage : formStage }
      />
      : null 
    }

    <Outlet />
  </ArtworkLayout>;
};
