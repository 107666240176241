export interface SelectionData {
  startX: number;
  startY: number;
  endX: number;
  endY: number;
}

export interface SelectionResult {
  base64: string;
  selection: SelectionData;
}

export enum ScreenshootProcessorEvents {
  UPDATE_SELECTION = 'update-selection',
  END_SELECTION = 'end-selection'
}

const SELECTION_THRESHOLD = 30;

export class ScreenshootProcessor {
  constructor() {
    this.beginSelection = this.beginSelection.bind(this);
    window.addEventListener('mousedown', this.beginSelection);
  }

  public clear() {
    window.removeEventListener('mousedown', this.beginSelection);
  }

  public on<T>(event: string, callback: (v: T) => void) {
    const handler = (e: CustomEvent) => callback(e.detail);
    window.addEventListener("screenshot-processor:" + event, handler);

    return () => window.removeEventListener("screenshot-processor:" + event, handler);
  }

  private correctSelectionData(sx: number, sy: number, ex: number, ey: number): SelectionData {
    return {
      startX: Math.min(sx, ex),
      endX: Math.max(sx, ex),
      startY: Math.min(sy, ey),
      endY: Math.max(sy, ey),
    }
  }

  private beginSelection(e: MouseEvent) {
    e.preventDefault();

    const startX = e.clientX;
    const startY = e.clientY;

    const moveHandler = (e: MouseEvent) => {
      const endX = e.clientX;
      const endY = e.clientY;

      this.trigger('update-selection', this.correctSelectionData(startX, startY, endX, endY));
    };

    window.addEventListener('mousemove', moveHandler);

    window.addEventListener('mouseup', (e: MouseEvent) => {
      const endX = e.clientX;
      const endY = e.clientY;

      window.removeEventListener('mousemove', moveHandler);

      const canvasElement = this.findNearestCanvas(document.elementFromPoint(startX, startY))
        || this.findNearestCanvas(document.elementFromPoint(endX, endY));

      if (!canvasElement) {
        this.trigger('end-selection', {});
        return;
      }

      const selectionData = this.correctSelectionData(startX, startY, endX, endY);
      const { startX: sx, startY: sy, endX: ex, endY: ey } = selectionData;

      const width = ex - sx;
      const height = ey - sy;

      if (width < SELECTION_THRESHOLD || height < SELECTION_THRESHOLD) {
        this.trigger('end-selection', {});
        return;
      }

      this.trigger('end-selection', {
        base64: this.getCanvasSelection(canvasElement, selectionData),
        selection: selectionData,
      });
    }, { once: true });
  }

  private findNearestCanvas(target: Element | null): HTMLCanvasElement | null {
    if (!target) return null;

    const result = Array.from(target.parentNode?.children || [])
      .find((child) => child instanceof HTMLCanvasElement);

    if (!result) return this.findNearestCanvas(target.parentNode as Element);

    return result as HTMLCanvasElement;
  }

  private getCanvasSelection(canvas: HTMLCanvasElement, selection: SelectionData) {
    const c = document.createElement('canvas').getContext('2d');
    if (!c) return null;

    let { startX, startY, endX, endY } = selection;
    const rect = canvas.getBoundingClientRect();
    const scaleX = canvas.width / rect.width;
    const scaleY = canvas.height / rect.height;

    startX -= rect.left;
    startY -= rect.top;
    endX -= rect.left;
    endY -= rect.top;

    startX *= scaleX;
    startY *= scaleY;
    endX *= scaleX;
    endY *= scaleY;

    const width = endX - startX;
    const height = endY - startY;

    c.canvas.width = width;
    c.canvas.height = height;

    c.drawImage(canvas, startX, startY, width, height, 0, 0, width, height);

    return c.canvas.toDataURL("image/jpeg");
  }

  private trigger(event: string, v: any) {
    window.dispatchEvent(new CustomEvent("screenshot-processor:" + event, { detail: v }));
  }
}
