import clsx from 'clsx';
import './index.scss';

import { Link, useParams } from 'react-router-dom';
import { OverlayScrollbarsComponent, OverlayScrollbarsComponentRef } from 'overlayscrollbars-react';

import { useFolders } from 'src/api/hooks';
import { resolveRoute } from 'src/router/routes';
import { Tooltip, TooltipInjections, TooltipPosition } from 'src/components/ui';
import { useCallback, useEffect, useRef, useState } from 'react';

export const FoldersPanel = () => {
  const params = useParams();
  const { folders } = useFolders();
  const [isTooltipsActive, setIsTooltipsActive] = useState(false);
  const scrollRef = useRef<OverlayScrollbarsComponentRef | null>(null);

  const onScroll = useCallback(() => {
    const scroll = scrollRef.current?.getElement();
    const content = scroll?.children[1] as HTMLElement;

    if (!scroll) {
      return;
    }

    const { top: scrollTop } = scroll!.getBoundingClientRect();

    for (let i = 0; i < content.children.length; i++) {
      const child = content.children[i].children[0] as TooltipInjections;

      const { top, height } = child.getBoundingClientRect();
      child._repositeTooltip?.();

      if (top + height / 2 > scrollTop && top + height / 2 < scrollTop + scroll.clientHeight) {
        child._setActive?.(isTooltipsActive);
      } else {
        child._setActive?.(false);
      }
    }
  }, [isTooltipsActive]);

  const items = folders.map((folder) => {
    const classList = clsx({
      'folders-panel__button-marker': true,
      'folders-panel__button-marker--active': folder.id === params.folderId,
    });

    const linkClassList = clsx({
      'folders-panel__button': true,
      'folders-panel__button--active': folder.id === params.folderId,
    });

    const folderLink = resolveRoute({ name: 'folder', params: { folderId: folder.id } });

    return <div
      className={ classList }
      key={folder.id}
    >
      <Tooltip
        content={ folder.name }
        position={ TooltipPosition.RIGHT }
        passive
      >
        <Link
          to={ folderLink }
          className={ linkClassList }
          data-initials={ folder.initials }
        />
      </Tooltip>
    </div>
  });

  useEffect(() => {
    onScroll();
  }, [isTooltipsActive, onScroll]);

  return <div
    className="folders-panel"
    onMouseOver={() => setIsTooltipsActive(true)}
    onMouseOut={() => setIsTooltipsActive(false)}
  >
    <OverlayScrollbarsComponent 
      className="folders-panel__scroll-container"
      options={{
        scrollbars: {
          autoHide: 'move',
        },
      }}
      events={{
        scroll: onScroll,
      }}
      ref={ scrollRef }
    >
      { items }
    </OverlayScrollbarsComponent>
  </div>
}
