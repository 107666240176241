import { Expose, Type } from "class-transformer";
import { MessageModel } from "./message.model";

export class MessageListModel {
  @Expose()
  pageSize: number;

  @Expose()
  totalCount: number;

  @Expose()
  pageCount: number;

  @Expose()
  currentPage: number;

  @Expose()
  previousPage: number;

  @Expose()
  nextPage: number;

  @Expose()
  @Type(() => MessageModel)
  results: MessageModel[];
}
