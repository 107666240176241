import 'reflect-metadata';
import './assets/styles/main.scss';
import 'react-pdf/dist/Page/TextLayer.css'
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'overlayscrollbars/overlayscrollbars.css';
import 'katex/dist/katex.min.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { Router } from './router';
import { pdfjs } from 'react-pdf';
import { GoogleOAuthProvider } from '@react-oauth/google';

const GOOGLE_CLIENT_ID = '742261058693-ghjt52n157flergt3vo0km6msi8tvjpl.apps.googleusercontent.com';
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

        
ReactDOM
  .createRoot(document.getElementById('root')!)
  .render(
    <React.StrictMode>
      <GoogleOAuthProvider clientId={ GOOGLE_CLIENT_ID }>
        <RouterProvider router={ Router } />
      </GoogleOAuthProvider>
    </React.StrictMode>
  );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
