import { Expose } from "class-transformer";

export class SubscriptionPlanModel {
  @Expose()
  public id: string;

  @Expose()
  public name: string;

  @Expose()
  public description: string;

  @Expose()
  public synonym: string;

  @Expose()
  public membersQuota: number;

  @Expose()
  public documentsQuota: number;

  @Expose()
  public messagesMonthQuota: number;

  @Expose()
  public price: number;
}
