import { Expose, Transform, Type } from "class-transformer";
import { FootnoteModel } from "./footnote.model";
import { ImageModel } from "./image.model";

export class MessageModel {
  @Expose()
  public id: string;

  @Expose()
  public userId: string;

  @Expose()
  public replyToId: string;

  @Expose()
  public folderId: string;

  @Expose()
  @Transform(({ value }) => (value || '')
    // NOTE: LaTeX to MathJax
    .replace(/(\\\[|\\\]|\\\(\s?|\s?\\\))/gm, '$$')

    // NOTE: New line to Markdown new line
    .replace(/\n/g, '  \n'))
  public content: string;

  @Expose()
  @Type(() => Date)
  public createdAt: Date;

  @Expose()
  @Type(() => FootnoteModel)
  public footnotes: FootnoteModel[];

  @Expose()
  @Type(() => ImageModel)
  public attachment?: ImageModel;

  @Expose()
  public isFromAssistant: boolean;

  @Expose()
  public isRead: boolean;

  public isProcessing: boolean = false;
}
