import "./index.scss";

import clsx from "clsx";
import { useCallback, useEffect, useState } from "react";

import type { SettingsTabProps } from "..";
import { Metric } from "src/components/ui";
import { SubscriptionModel } from "src/api/models/subscription.model";
import { ClarityDocsApi } from "src/api";
import { useTeam } from "src/api/hooks/useTeam";
import { UpgradePlanPopup } from "./UpgradePlanPopup";
import { useMembership } from "src/api/hooks";

const session = new ClarityDocsApi();

export const SubscriptionTab = (props: SettingsTabProps) => {
  const { allowedFor } = useMembership();
  const { team } = useTeam();

  const [isPending, setIsPending] = useState(false);
  const [subscription, setSubscription] = useState<SubscriptionModel | null>(null);

  const upgdatePopupState = useState(false);
  const isFreePlan = !subscription?.plan.price;

  const upgradePlan = useCallback(() => {
    if (isFreePlan) {
      upgdatePopupState[1](true);
      return;
    }

    if (!team) return;
    setIsPending(true);
    session.team.getBilingPortalUrl(team.id)
      .then(url => window.location.href = url)
      .finally(() => setIsPending(false));
  }, [isFreePlan, team, upgdatePopupState]);

  useEffect(() => {
    if (!team) return;
    session.team.getSubscription(team.id).then(setSubscription);
  }, [team]);

  return <div className="subscription-tab flex flex--col flex--gap24">
    <section className={clsx({
      "subscription-tab__properties": true,
      "skeleton skeleton--light": true,
      "skeleton--active": !subscription
    })}>
      <div className="subscription-tab__prop">
        <p className="subscription-tab__prop-name" children="Plan" />
        <p className="subscription-tab__prop-value" children={ subscription?.plan.name || 'Loading...' } />
      </div>
      <div className="subscription-tab__prop">
        <p className="subscription-tab__prop-name" children="Payment" />
        <p className="subscription-tab__prop-value" children={`$${subscription?.plan.price}/month`} />
      </div>
      <div>
        { allowedFor(session.team.roles.OWNER) && <button 
          className={ clsx({
            "button button--link": true,
            "button--pending": isPending,
          }) }
          children={ isFreePlan ? 'Upgrade' : 'Manage subscription' }
          onClick={ upgradePlan }
        /> }
      </div>
    </section>

    <div className="divider" />

    <section className="info-message">
      <p>
        Here are your team's total usage stats. 
        The message count is shown as a total, not per member.
        Upgrade your plan if you're nearing the limit.
      </p>
    </section>

    <section className={ clsx({
      "subscription-tab__usage": true,
      "skeleton skeleton--light": true,
      "skeleton--active": !subscription,
    })}>
      <Metric
        name="Members"
        icon="team"
        max={ subscription?.plan.membersQuota || 0 }
        value={ subscription?.members || 0 }
      />
      <Metric
        name="Messages"
        icon="message"
        max={ subscription?.plan.messagesMonthQuota || 0 }
        value={ subscription?.messagesMonth || 0 }
      />
      <Metric
        name="Documents"
        icon="document"
        max={ subscription?.plan.documentsQuota || 0 }
        value={ subscription?.documents || 0 }
      />
    </section>

    <div className="flex flex--jend">
      <div
        className="button"
        children="Cancel"
        onClick={ props.requestClose }
      />
    </div>
    
    <UpgradePlanPopup
      statePair={ upgdatePopupState }
      currentPlan={ subscription?.plan! }
    />
  </div>
}
