import { Link } from "react-router-dom";
import { ArtworkLayout } from "src/components/layouts/ArtworkLayout";
import { resolveRoute } from "src/router/routes";

import SUCCESS_IMAGE from "src/assets/images/tick-circle.svg";

export const Page = () => {
  return <ArtworkLayout>
    <div 
      className="flex flex--col flex--acenter flex--jcenter"
      style={{
        height: '100vh',
      }}
    >
      <div className="tx-center">
        <img src={ SUCCESS_IMAGE } alt="Error" width="100" />
      </div>
      <p className="txc-placeholder">
        Your subscription has been activated
      </p>

      <Link
        className="button button--primary" 
        to={ resolveRoute({ name: 'main' }) }
        children="Go to app"
      />
    </div>
  </ArtworkLayout>
}
