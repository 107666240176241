import { useState } from "react"
import { CreateFolderPopup } from "src/components/shared/CreateFolderPopup";

export const Name = 'create-folder';

export const Page = () => {
  const createPopupState = useState(true);

  return <div>
    <CreateFolderPopup
      persistent
      statePair={ createPopupState }
    />
  </div>;
}
