import clsx from "clsx";
import { useState } from "react";
import { useTeamMemberships } from "src/api/hooks/useTeamMemberships";
import { SearchBar } from "src/components/ui/SearchBar";
import { InvitationList } from "./InvitationList";
import { InvitiationPopup } from "./InvitationPopup";
import { MemberList } from "./MemberList";
import type { SettingsTabProps } from "..";

export const ManageMembersTab = (props: SettingsTabProps) => {
  const [query, setQuery] = useState('');
  const [currentList, setCurrentList] = useState(0);
  const { memberships, invitations } = useTeamMemberships();
  const invitationPopupState = useState(false);

  const ListToShow = [MemberList, InvitationList][currentList];

  return <div className="manage-members-tab flex flex--col flex--gap24">
    <div className="manage-members-tab__actions">
      <button 
        className={clsx({
            "button button--gray": true,
            "button--active": currentList === 0,
        })}
        children={ `Members (${memberships.length})` }
        onClick={ () => setCurrentList(0) }
      />
      <button
        className={clsx({
            "button button--gray": true,
            "button--active": currentList === 1,
        })}
        children={ `Invitations (${invitations.length})` }
        onClick={ () => setCurrentList(1) }
      />

      <SearchBar onInput={ setQuery } />

      <button 
        className="button button--primary"
        onClick={ () => invitationPopupState[1](true) }
      >
        <i className="icon-plus" />
        <span children="Invite member"></span>
      </button>
    </div>
    <div 
      className="manage-members-tab__scroll-wrapper"
      children={ <ListToShow query={ query } /> }
    />

    <div className="flex flex--jend">
      <button 
        className="button"
        children="Cancel" 
        onClick={ props.requestClose }
      />
    </div>

    <InvitiationPopup statePair={ invitationPopupState } />
  </div>;
}
