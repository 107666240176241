import { useCallback, useEffect, useState } from "react";
import { ClarityDocsApi } from "..";
import { FolderModel, FolderUpdateModel } from "../models/folder.model";
import { ClarityFoldersEvent } from "../services/folders.service";

const session = new ClarityDocsApi();

interface UploadInfo {
  progress: number;
  error: string;
  isPending: boolean;
  file: File;
}

type Filename = string;
type ProgressHandler = (progress: number) => void;

export const useFolders = () => {
  const [folders, setFolders] = useState<FolderModel[]>(session.folders.list);
  const [isPending, setIsPending] = useState(false);
  const [uploadProgresses, setProgresses] = useState<Record<Filename, UploadInfo>>({});

  // NOTE: Listen for folder updates
  useEffect(() => session
    .on(ClarityFoldersEvent.FOLDERS_UPDATED, setFolders), []);

  const createFolder = useCallback(async (payload: FolderUpdateModel) => {
    setIsPending(true);
    return session.folders.create(payload)
      .catch(console.error)
      .finally(() => setIsPending(false));
  }, []);

  const updateFolder = useCallback(async (id: string, payload: FolderUpdateModel) => {
    setIsPending(true);
    return session.folders.update(id, payload)
      .catch(() => null)
      .finally(() => setIsPending(false));
  }, []);

  const removeFolder = useCallback(async (id: string) => {
    setIsPending(true);
    return session.folders.delete(id)
      .catch(() => null)
      .finally(() => setIsPending(false));
  }, []);

  const uploadDocument = useCallback(async (folderId: string, file: File) => {
    setProgresses(progresses => ({
      ...progresses,
      [file.name]: {
        progress: 0,
        error: '',
        isPending: true,
        file,
      }
    }))

    const onProgress: ProgressHandler = (progress) => {
      setProgresses(progresses => ({
        ...progresses,
        [file.name]: {
          ...progresses[file.name],
          progress,
        }
      }));
    }

    return session.documents.upload(folderId, file, onProgress)
      .then(() => {
        setProgresses(progresses => ({
          ...progresses,
          [file.name]: {
            ...progresses[file.name],
            isPending: false,
          }
        }));
      })
      .catch((e) => {
        setProgresses(progresses => ({
          ...progresses,
          [file.name]: {
            ...progresses[file.name],
            progress: 0,
            error: e?.response?.data?.message || "An error occurred",
            isPending: false,
          }
        }));
      });
  }, []);

  const clearUploadProgress = useCallback(() => {
    setProgresses({});
  }, []);


  const uploadDocuments = useCallback(async (folderId: string, files: File[]) => {
    const promises = files.map(file => uploadDocument(folderId, file));

    setIsPending(true);
    return Promise.all(promises)
      .finally(() => setIsPending(false));
  }, [uploadDocument]);

  const getFolder = useCallback((id: string) => session.folders.get(id), []);

  return {
    session,
    folders,
    uploadProgresses,
    isPending,
    getFolder,
    createFolder,
    removeFolder,
    updateFolder,
    uploadDocument,
    uploadDocuments,
    clearUploadProgress,
  };
}
