import clsx from 'clsx';
import { FormEventHandler, useCallback } from 'react';
import { Link } from 'react-router-dom';

import { useUser } from 'src/api/hooks';
import { LoginModel } from 'src/api/models/login.model';

import { resolveRoute, useNavigate } from 'src/router/routes';
import { getPayload } from 'src/utils/objectUtils';
import { rules } from 'src/utils/validation';

import { GoogleSignInButton } from 'src/components/shared/GoogleSignInButton';
import { TextField, Form } from 'src/components/ui/index';
import { ArtworkLayout } from 'src/components/layouts/ArtworkLayout';

import IMAGE_ARTWORK from 'src/assets/images/artwork.jpg';
import { LoginGuard } from 'src/guards/login.guard';

export const Loader = LoginGuard('signin');

export const Name = 'signin';

export const Page = () => {
  const navigate = useNavigate();
  const { login, isPending } = useUser();

  const onSubmit: FormEventHandler<HTMLFormElement> = useCallback(async (e) => {
    e.preventDefault();

    const payload = getPayload<LoginModel>(e.currentTarget as HTMLFormElement);
    const success = await login(payload);

    if (!success) {
      return;
    }

    navigate({ name: 'root' });
  }, [login, navigate]);

  const submitButtonClass = clsx({
    'button': true,
    'button--primary': true,
    'button--fluid': true,
    'button--pending': isPending,
  });

  return <ArtworkLayout imageSrc={ IMAGE_ARTWORK }>
    <Form className="flex flex--col flex--gap48" onSubmit={ onSubmit }>
      <h1 className="tx-center tx-section-heading">
        Welcome back
      </h1>

      <div className="flex flex--col flex--gap30">
        <TextField 
          label="Email" 
          name="email"
          placeholder="Enter email"
          rules={[
            rules.required(),
            rules.email(),
          ]}
        />

        <div className="flex flex--col">
          <TextField 
            label="Password" 
            name="password"
            placeholder="Enter password"
            password
            rules={[
              rules.required(),
            ]}
          />

          <div className="tx-right tx-small">
            <Link to="/reset-password">
              Forgot password
            </Link>
          </div>
        </div>

        <button type="submit" className={ submitButtonClass }>
          Sign in
        </button>
        
        <p className="txc-placeholder tx-center">
          Don't have an account? &nbsp;
          <Link to={ resolveRoute({ name: 'signup' })} children="Sign up" />
        </p>

        <div className="divider">
          or
        </div>

        <GoogleSignInButton 
          disabled={ isPending }
          label="Sign in with Google"
        />
      </div>
    </Form>
  </ArtworkLayout>;
};
