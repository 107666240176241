import { Expose, Type } from "class-transformer";
import { SubscriptionPlanModel } from "./subscriptionPlan.model";

export class SubscriptionModel {
  @Expose()
  public members: number;

  @Expose()
  public messagesMonth: number;

  @Expose()
  public documents: number;

  @Expose()
  @Type(() => SubscriptionPlanModel)
  public plan: SubscriptionPlanModel;
}
