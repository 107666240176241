import { Popup, PopupProps } from "src/components/ui";
import { ProfileForm } from "./Form";

export const ProfileSettingsPopup = (props: Omit<PopupProps, 'children'>) => {

  return <Popup
    title="Edit profile"
    width={ 750 }
    {...props}
  >
    <ProfileForm onRequestClose={ () => props.statePair[1](false) } />
  </Popup>
}
