import { Expose, Transform, Type,  } from "class-transformer";
import { Role } from "../services/team.service";
import { InvitationModel } from "./invitation.model";
import { LogoModel } from "./logo.model";
import { MembershipModel } from "./membership.model";

export enum TeamStatus {
  ACTIVATED = '921f05be-775f-4a33-bfce-69be20ee87bb',
  DELETION_REQUESTED = '5f6cd170-acb6-4651-8a1e-e2927423175b',
  SUSPENDED = 'ae4c249e-65a7-46ad-a1df-249a7bccb2a4',
}

export class TeamModel {
  @Expose()
  public id: string;

  @Expose({ name: 'statusId' })
  public status: string;

  @Expose()
  public name:  string;

  @Expose()
  @Transform(({ value }) => value || '')
  public website: string;

  @Expose()
  @Transform(({ value }) => value || '')
  public description: string;

  @Expose()
  @Type(() => LogoModel)
  public logo: LogoModel;

  @Expose({ name: 'roleId' })
  public role: Role;

  public memberships: MembershipModel[] = [];

  public invitations: InvitationModel[] = [];

  clone(newData: Partial<TeamModel> = {}) {
    return Object.assign(new TeamModel(), this, newData);
  }

  get initials() {
    return this.name
      .split(' ')
      .map(w => w[0])
      .join('')
      .toUpperCase()
      .slice(0, 2);
  }
}

export type TeamUpdateModel = Pick<TeamModel, 'name' | 'website' | 'description'>;
