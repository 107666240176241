import { AxiosInstance } from "axios";
import { plainToInstance } from "class-transformer";
import { ClarityDocsApi, ClarityDocsEvent } from "..";
import { JsonDecoder } from "../decoder";
import { KeepPromise } from "../decorators";
import { AssistantModel } from "../models/assistant.model";
import { FolderModel } from "../models/folder.model";
import { MessageModel } from "../models/message.model";
import { MessageListModel } from "../models/messageList.model";
import { MessageMeta, MessageMetaType } from "../models/messageMeta.model";

export enum ClarityChatEvent {
  MESSAGE_RECEIVED = 'message-received',
  MESSAGE_UPDATED = 'message-updated',
}

export interface MessagePayload {
  message: string;
  attachment?: string;
}

export class ChatService {
  private client: AxiosInstance;

  private assistantRecords: Record<string, AssistantModel> = {};
  private assistants: AssistantModel[] = [];
  private startedMessage: MessageModel | null = null;
  private abortController: AbortController | null = null;

  constructor(private api: ClarityDocsApi) {
    this.client = (api as any).client as AxiosInstance;
  }

  public getAssistant(id: string) {
    return this.assistantRecords[id] || null;
  }

  public async init() {
    const { data } = await this.client.get<AssistantModel[]>('/assistants');
    this.assistants = plainToInstance(AssistantModel, data, { excludeExtraneousValues: true });

    this.assistants.forEach(a => {
      this.assistantRecords[a.id] = a;
    });
  }

  @KeepPromise()
  public async getMessages(folderId: string, page: number, pageSize: number) {
    const { data } = await this.client.get<MessageListModel>('/messages', { params: {
      folderId: folderId,
      currentPage: page,
      pageSize: pageSize,
    }});

    if (!data) {
      return null;
    }

    const folder = this.api.folders.get(folderId);

    if (!folder) {
      return null;
    }

    const list = plainToInstance(MessageListModel, data, { excludeExtraneousValues: true });

    list.results.forEach(message => {
      folder.messages[message.id] = message;
    });

    return list.results;
  }

  private parseMessageMeta(folder: FolderModel, meta: MessageMeta) {
    const { message, messageId, type, content } = meta;
    const { id: folderId } = folder;

    switch (type) {
      case MessageMetaType.MESSAGE_START:
      case MessageMetaType.MESSAGE:
        if (folder.messages[message.id]) {
          return;
        }

        if (type === MessageMetaType.MESSAGE_START) {
          message.isProcessing = true;
          this.startedMessage = message;
        }

        folder.messages[message.id] = message;

        this.api.dispatch(ClarityChatEvent.MESSAGE_RECEIVED, {
          folderId,
          message,
        });
        break;

      case MessageMetaType.MESSAGE_UPDATE:
        const targetMessage = folder.messages[messageId];
        targetMessage.content += (content || '');
        targetMessage.content = targetMessage.content
          // NOTE: LaTeX to MathJax
          .replace(/(\\\[|\\\]|\\\(\s?|\s?\\\))/gm, '$$')

          // NOTE: New line to Markdown new line
          .replace(/\n/g, '  \n')

        this.api.dispatch(ClarityChatEvent.MESSAGE_UPDATED, {
          folderId,
          message: targetMessage,
        });
        break;

      case MessageMetaType.MESSAGE_END:
        folder.messages[message.id] = message;
        message.isProcessing = false;

        this.api.dispatch(ClarityChatEvent.MESSAGE_UPDATED, {
          folderId,
          message: folder.messages[message.id],
        });
        break;
    }
  }

  public async abortMessage() {
    if (!this.abortController) {
      return;
    }

    if (this.startedMessage) {
      const folder = this.api.folders.get(this.startedMessage.folderId)
      const message = folder.messages[this.startedMessage.id];
      message.isProcessing = false;

      this.api.dispatch(ClarityChatEvent.MESSAGE_UPDATED, {
        folderId: folder.id,
        message,
      });
    }

    this.abortController.abort('Aborted by user');
  }

  public async sendMessage(payload: MessagePayload, folderId: string) {
    const folder = this.api.folders.get(folderId);
    const documentIds = folder.pinnedDocuments.length
      ? folder.pinnedDocuments.map(doc => doc.id)
      : undefined;

    const body = JSON.stringify({
      folderId,
      documentIds,
      ...payload,
    });
    
    this.abortController = new AbortController();
    const { signal } = this.abortController;

    await fetch(`${this.client.defaults.baseURL}/message`, {
        headers: {
          ...this.client.defaults.headers as HeadersInit,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body,
        signal,
    })
    .then(response => {
      if (!response.ok) {
        const reqError = new Error("Request failed with status code " + response.status);
        (reqError as any).response = response;

        return this.api.handleRequestError(reqError);
      } 
      
      return response.body
    })
    .then(body => {
      if (!body) return;

      const reader = body.getReader();
      const decoder = new JsonDecoder();

      const processText = async ({ done, value }: { done: boolean, value: Buffer }): Promise<void> => {
        if (done) {
          return;
        }

        decoder.decode<MessageMeta>(value, (metaRaw) => {
          const meta = plainToInstance(MessageMeta, metaRaw, { excludeExtraneousValues: true });
          this.parseMessageMeta(folder, meta);
        })

        return reader.read().then(processText);
      };

      return reader.read().then(processText);
    })
    .catch(err => {
      if (!this.startedMessage) return err.response;

      this.parseMessageMeta(folder, {
        type: MessageMetaType.MESSAGE_END,
        messageId: this.startedMessage.id,
        content: '',
        message: this.startedMessage,
      });

      this.api.dispatch(ClarityDocsEvent.API_ERROR_MESSAGE, 'Something went wrong while sending the message');

      return err.response;
    })
  }
}
