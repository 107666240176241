import './index.scss';

import clsx from 'clsx';
import { useState, useEffect, useCallback } from 'react';
import { Outlet, useParams } from "react-router-dom"

import { useNavigate } from 'src/router/routes';
import { useDocuments, useFolders } from "src/api/hooks";

import { DocumentUploader } from 'src/components/shared/DocumentUploader';
import { ConfirmPopup } from 'src/components/shared/ConfirmPopup';
import { CreateFolderPopup } from 'src/components/shared/CreateFolderPopup';
import { WorkspaceLayout } from 'src/components/layouts/WorkspaceLayout';
import { DocumentList } from 'src/components/shared/DocumentList';
import { SearchBar } from 'src/components/ui/SearchBar';
import { Tooltip, TooltipPosition } from 'src/components/ui';

export const Name = 'folder';

let resizeTimeout: ReturnType<typeof setTimeout> | null = null;

export const Page = () => {
  const params = useParams();
  const navigate = useNavigate();

  const {
    getFolder,
    removeFolder,
    isPending: isPendingFolder,
    folders,
  } = useFolders();

  const { isInvalidFolder } = useDocuments(params.folderId);

  const searchQueryState = useState('');
  const folderEditPopupState = useState(false);
  const folderDeletePopupState = useState(false);
  const [docPanelActive, setDocPanelActive] = useState(false);

  const folder = getFolder(params.folderId!);

  const openDocument = useCallback((docId: string) => {
    navigate({
      name: 'document',
      params: { folderId: params.folderId!, documentId: docId },
    });
  }, [navigate, params.folderId]);

  const requestRemoveFolder = useCallback(async (confirm = false) => {
    if (!confirm) {
      folderDeletePopupState[1](true);
      return;
    }

    await removeFolder(params.folderId!);

    const [firstFolder] = folders.filter(folder => folder.id !== params.folderId);

    if (!firstFolder) {
      navigate({ name: 'create-folder' });
      return;
    }

    const { id: folderId } = firstFolder;

    navigate({
      name: 'folder',
      params: { folderId },
    });

    folderDeletePopupState[1](false);
  }, [removeFolder, params.folderId, navigate, folderDeletePopupState, folders]);

  // NOTE: Redirects to the first folder if the current folder is invalid
  useEffect(() => {
    if (!isInvalidFolder) {
      return 
    }

    const [firstFolder] = folders;

    if (!firstFolder) {
      navigate({ name: 'create-folder' });
      return;
    }

    navigate({
      name: 'folder',
      params: { folderId: firstFolder.id },
    });
  }, [isInvalidFolder, isPendingFolder, navigate, folders]);

  // NOTE: Make document viewer responsive when the doc panel state changes
  useEffect(() => {
    if (resizeTimeout) {
      clearTimeout(resizeTimeout);
    }

    resizeTimeout = setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 300);
  }, [docPanelActive])

  // NOTE: Open the doc panel if the folderId changed
  useEffect(() => {
    if (params.folderId) {
      setDocPanelActive(true);
    }
  }, [params.folderId]);

  useEffect(() => {
    const isFoldersEmpty = !folders.length;
    const isExists = folders.some(folder => folder.id === params.folderId);

    if (isPendingFolder) {
      return;
    }

    if (isFoldersEmpty) {
      navigate({ name: 'create-folder' });
      return;
    }

    if (!isExists) {
      navigate({
        name: 'folder',
        params: { folderId: folders[0].id },
      });
    }

  }, [folders, params.folderId, navigate, isPendingFolder]);

  if (!params.folderId) {
    return null;
  }

  return <WorkspaceLayout>
    <div
      className={clsx({
        folder: true,
        'folder--active': docPanelActive,
      })}
    >
      <button 
        onClick={ () => setDocPanelActive(!docPanelActive) }
        className="folder__fold-button"
        title="Fold"
      />

      <div className="folder__overflow-container">
        <section className="docs-panel">
          <header className="docs-panel__header">
            <h2 className="tx-section-heading">
              { folder?.name }
            </h2>
            <div className="tx-right flex flex--gap8 flex--jend flex-auto">
              <Tooltip content="Delete folder" position={ TooltipPosition.BOTTOM } >
                <button onClick={ () => requestRemoveFolder() } title="Delete folder">
                  <i className="icon-delete"></i>
                </button>
              </Tooltip>

              <Tooltip content="Edit folder" position={ TooltipPosition.BOTTOM }>
                <button title="Edit folder" onClick={() => folderEditPopupState[1](true) }>
                  <i className="icon-edit"></i>
                </button>
              </Tooltip>
            </div>
          </header>

          <SearchBar
            placeholder="Search"
            statePair={ searchQueryState }
          />

          <div className="docs-panel__list-measure-wrapper">
            <DocumentList 
              folderId={ params.folderId! }
              searchQuery={ searchQueryState[0] }
              documentId={ params.documentId }
            />
          </div>

          <DocumentUploader 
            folderId={params.folderId!}
            onDocumentUploaded={openDocument}
            instantUpload
          />
        </section>
      </div>
      <Outlet />
      
      <ConfirmPopup
        title="Delete folder"
        statePair={ folderDeletePopupState }
        message={ 'This action will delete your folder, all the documents in it, and the history of communication. Are you sure?' }
        onConfirm={ () => requestRemoveFolder(true) }
        isPending={ isPendingFolder }
        confirmText="Delete"
        danger
      />

      <CreateFolderPopup
        edit
        statePair={ folderEditPopupState }
        folderId={ params.folderId! }
      />
    </div>
  </WorkspaceLayout>
}
