import './index.scss';

import { useRef, useState, useEffect, useCallback } from 'react';
import clsx from 'clsx';
import { showErrorMessage } from 'src/utils/notyf';
import { useFolders } from 'src/api/hooks';
import { Popup } from 'src/components/ui';

interface DocumentUploaderProps {
  folderId: string;
  label?: string;
  name?: string;
  onDocumentUploaded?: (docId: string) => void;
  instantUpload?: boolean;
}

const DEFAULT_LABEL = 'Select or drop your files (pdf, doc, docx, odt) here';

const ALLOWED_MIMES = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.oasis.opendocument.text',
]

const FILE_SIZE_LIMIT = 50;

export const DocumentUploader = (props: DocumentUploaderProps) => {
  const { uploadDocuments, uploadProgresses, isPending, clearUploadProgress } = useFolders();

  const inputFileRef = useRef<HTMLInputElement>(null);
  const fileListRef = useRef<HTMLUListElement>(null);

  const popupActiveState = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [currentLabel, setCurrentLabel] = useState(props.label || DEFAULT_LABEL);

  const onDragOver = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(true);
  }, []);
  
  const onDragLeave = useCallback(() => setIsDragging(false), []);
  const closePopup = useCallback(() => popupActiveState[1](false), [popupActiveState]);

  const noErrors = useCallback(() => !Object
      .values(uploadProgresses)
      .some(progress => !!progress.error), [uploadProgresses]);

  const onFileReceived = useCallback((newFiles: FileList) => {
    const filteredNewFiles = Array
      .from(newFiles)
      .filter(file => {
        if (!ALLOWED_MIMES.includes(file.type)) {
          showErrorMessage('File is not valid: ' + file.name);
          return false;
        }

        if (file.size > FILE_SIZE_LIMIT * 1024 * 1024) {
          showErrorMessage(`${file.name}: File size should be less than ${FILE_SIZE_LIMIT}MB`);
          return false;
        }

        return true;
      });

    uploadDocuments(props.folderId, filteredNewFiles);
  }, [uploadDocuments, props.folderId]);

  const onDrop = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);
    onFileReceived(e.dataTransfer.files);
  }, [onFileReceived]);

  const onInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || !e.target.files.length) { return; }
    onFileReceived(e.target.files);
    e.target.value = '';
  }, [onFileReceived]);

  const openFileDialog = useCallback(() => {
    inputFileRef.current?.click();
  }, []);

  const dropzoneClassList = clsx({
    'doc-uploader__dropzone': true,
    'doc-uploader__dropzone--dragging': isDragging,
    'doc-uploader__dropzone--pending': isPending,
  })

  const filesEntries = Object.entries(uploadProgresses);

  // NOTE: Close popup once all files loaded successfully
  useEffect(() => {
    const hasFiles = Object.keys(uploadProgresses).length > 0;

    if (hasFiles && !isPending && noErrors()) {
      closePopup();
      clearUploadProgress();
    }
  }, [isPending, closePopup, clearUploadProgress, uploadProgresses, noErrors]);

  // NOTE: Control label text based on current state
  useEffect(() => {
    if (isPending) {
      const uploadingLabel = 'Processing...';
      setCurrentLabel(uploadingLabel);
    } else if (isDragging) {
      setCurrentLabel('Drop the file here');
    } else {
      setCurrentLabel(props.label || DEFAULT_LABEL);
    }
  }, [isDragging, props.label, isPending]);

  return <div className="doc-uploader">
    <button
      className="doc-uploader__trigger button button--primary"
      onClick={ popupActiveState[1].bind(null, true) }
    >
      <i className="icon-upload"></i> Upload documents
    </button>

    <Popup
      title="Upload documents"
      statePair={ popupActiveState }
      width={ 600 }
      onClose={ clearUploadProgress }
    >
      <section className="info-message">
        <p>
          The main format of documents in the application is pdf.
          File formats other than pdf, such as doc, docx, and odt, will be converted.
        </p>
      </section>
      <button
        className={ dropzoneClassList }
        type="button"
        onDragOver={ onDragOver }
        onDrop = { onDrop }
        onDragLeave = { onDragLeave }
        onClick = { openFileDialog }
      >
        <input
          ref={ inputFileRef }
          type="file"
          onChange={ onInputChange }
          accept={ ALLOWED_MIMES.join(',') }
          multiple
        />

        <i className="doc-uploader__icon icon-upload"></i>

        <p className="doc-uploader__label">
          { currentLabel }
        </p>
      </button>

      <ul 
        className="doc-uploader__list"
        ref={ fileListRef }
      >
        { 
          filesEntries.map(([filename, progressData]) => <li key={filename}>
            <p className="doc-uploader__filename">{ filename }</p>
            <div className="flex flex--acenter flex--gap8">
              <div
                className={clsx({
                  'doc-uploader__item': true,
                  'doc-uploader__item--error': !!progressData.error,
                })}
                style={{ '--progress': progressData.progress } as React.CSSProperties}
              />
            </div>
            { progressData.error && <p className="tx-small txc-danger">{ progressData.error }</p> }
          </li>) 
        }
      </ul> 
    </Popup>
  </div>
}
