import "./index.scss";

import { useState } from "react";
import { Avatar } from "src/components/ui";
import { showErrorMessage } from "src/utils/notyf";

interface AvatarFieldProps {
  name: string;
  entityName?: string;
  label?: string;
  onChange?: (file: File) => void;
  src?: string;
}

const IMAGE_SIZE_LIMIT = 3;

export const AvatarUploader = (props: AvatarFieldProps) => {
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (!file) {
      return;
    }

    if (file.size > IMAGE_SIZE_LIMIT * 1024 * 1024) {
      showErrorMessage(`File size should be less than ${IMAGE_SIZE_LIMIT}MB`);
      return;
    }

    const reader = new FileReader();
    reader.onload = () => setPreviewUrl(reader.result as string);
    reader.readAsDataURL(file);

    props.onChange?.(file);

    event.target.value = '';
  }

  return <label className="avatar-field field">
    { props.label && <div className="field__label" children={ props.label } /> }

    <Avatar
      src={ previewUrl || props.src || '' }
      entityName={ props.entityName }
    />
    <input 
      type="file" 
      accept="image/jpg, image/jpeg, image/png" 
      name={ props.name }
      onChange={ onChange } 
    />
  </label>
}
