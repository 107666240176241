import { useState } from "react";
import { ClarityDocsApi } from "src/api";
import { InvitationModel } from "src/api/models/invitation.model";
import { PopupProps } from "src/components/ui"
import { ConfirmPopup } from "../../ConfirmPopup";

const session = new ClarityDocsApi();

interface MemberDeleteConfirmPopupProps extends Omit<PopupProps, 'children'> {
  invitation: InvitationModel;
}

export const InvitationDeleteConfirmPopup = (props: MemberDeleteConfirmPopupProps) => {
  const [isPending, setIsPending] = useState(false);

  const confirmDelete = () => {
    setIsPending(true);
    session.team.revokeInvitation(props.invitation)
      .finally(() => {
        setIsPending(false);
        props.statePair[1](false);
      });
  }

  return <ConfirmPopup
    statePair={ props.statePair }
    title="Remove member"
    message="Are you sure you want to revoke this invitation?"
    isPending={ isPending }
    onConfirm={ confirmDelete }
    confirmText="Delete"
    danger
  />
}
