import moment from "moment";
import { useState } from "react";
import { ClarityDocsApi } from "src/api";
import { useTeamMemberships } from "src/api/hooks/useTeamMemberships";
import { Avatar } from "src/components/ui";
import { Select } from "src/components/ui/Select";
import { MemberDeleteConfirmPopup } from "./MemberDeleteConfirmPopup";

import type { Role } from "src/api/services/team.service";
import { MembershipModel } from "src/api/models/membership.model";
import clsx from "clsx";
import { useMembership } from "src/api/hooks";

const session = new ClarityDocsApi();

export const MemberList = (props: { query: string }) => {
  const { allowedFor } = useMembership();
  const { memberships, isPending, changeRole } = useTeamMemberships();
  const deleteConfirmState = useState(false);
  const [memberToRemove, setMemberToRemove] = useState<string | null>(null);

  const headers = [
    'Name', 'Role', 'Joined', 'Action',
  ].map(title => <th key={ title }>{title}</th>);

  const rolesList = session.team.roleList
    .map(role => {
      const isDisabled = role.id === session.team.roles.OWNER && !allowedFor(session.team.roles.OWNER);

      return {
        value: role.id,
        label: role.name,
        disabled: isDisabled,
      };
    });

  const requestMemberDelete = (memberId: string) => {
    setMemberToRemove(memberId);
    deleteConfirmState[1](true);
  }

  const list = memberships.length < 1
    // NOTE: Skeleton loading
    ? Array
      .from({ length: 3 })
      .map((_, i) => Object.assign(new MembershipModel(), {
        id: i.toString(),
        user: {
          name: 'Loading...',
          email: 'loading@loading-site.com',
        }
      }))
    : memberships;

  const rows = list
    .filter(membership => membership.user.name
      .toLowerCase().includes(props.query.toLowerCase()))
    .map((membership) => {
      const roleSelect = <Select
        value={ membership.role }
        disabled={ !membership.canChangeRole }
        items={ rolesList } 
        pending={ isPending }
        onChange={ (role: Role) => changeRole(membership.id, role) }
      />

      const removeTrigger = <button
        className="button button--link"
        disabled={ !membership.canDelete }
        children="Remove"
        onClick={ () => requestMemberDelete(membership.id)}
      />

      const name = <div className="flex flex--acenter">
        <Avatar
          entityName={ membership.user.name }
          appearance="primary"
          src={ membership.user.image?.url }
        />
        <div className="flex flex--col flex--gap4 flex--astart">
          <p children={ membership.user.name } />
          <p children={ membership.user.email } />
        </div>
      </div>
          
      return <tr key={ membership.id }>
        <td children={ name }/>
        <td width="140" children={ roleSelect }/>
        <td width="130" >
          <span children={ moment(membership.createdAt).format('DD MMM YYYY') } />
        </td>
        <td width="120" children={ removeTrigger } />
      </tr>
    });

    return <>
      <table 
        className={ clsx({
          "manage-members-tab__table table": true,
          "skeleton skeleton--light": true,
          "skeleton--active": memberships.length < 1,
        }) }
        cellSpacing={0}
        cellPadding={0}
      >
        <thead>
          <tr children={ headers } />
        </thead>
        <tbody children={ rows } />
      </table>

      <MemberDeleteConfirmPopup
        statePair={ deleteConfirmState } 
        userId={ memberToRemove! } 
      />
    </>
}
