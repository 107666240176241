import clsx from "clsx";

interface DropdownHeaderProps {
  children?: React.ReactNode;
  noPadding?: boolean;
}

export const DropdownHeader = (props: DropdownHeaderProps) => {
  return <div
    className={clsx({
      'dropdown__header': true,
      'dropdown__header--no-padding': props.noPadding
    })}
  >
    { props.children }
  </div>
}
