import './index.scss';

import { FootnoteModel } from "src/api/models/footnote.model";
import { Link, useParams } from 'react-router-dom';
import Markdown from 'react-markdown';
import RemarkGFM from 'remark-gfm';
import { useState } from 'react';
import clsx from 'clsx';

const HIGHLIGHTS_ENABLED = true;

export const Footnote = (props: FootnoteModel) => {
  const params = useParams();
  const [isOpened, setOpened] = useState(false);

  const highlights = isOpened ? props.highlights
    .map((highlight, index) => (
      <div 
        key={ index }
        className="footnote__quote-wrapper"
      >
        <div className="footnote__quote">
          <Markdown remarkPlugins={[ RemarkGFM ]}>
           { highlight.content }
          </Markdown>
        </div>
      </div>
    )) : null;

  const src = `/main/${params.folderId}/${props.documentId}?page=${props.pageNumber}`;

  const toggleHighlights: React.MouseEventHandler = (e) => {
    e.preventDefault();

    setOpened(!isOpened);
  }

  return <Link to={ src } className="footnote">
    <div className="footnote__header">
      <p className="footnote__docname-label flex flex--acenter flex--gap8" title={ props.documentFilename }> 
        <span className="footnote__fade-wrapper">
          <i className="icon-external-link"></i>
          { props.documentFilename } 
        </span>
      </p>

      <div className="footnote__state">
        <i className="icon-document"></i>
        <span>
          { props.pageNumber }
        </span>
      </div>

      <button
        type="button"
        className={clsx({
          'footnote__unfold-button': true,
          'footnote__unfold-button--active': isOpened,
        })}
        disabled={ !props.highlights.length }
        onClick={ toggleHighlights }
      />
    </div>

    { HIGHLIGHTS_ENABLED && highlights && <div className="footnote__highlights">
      { highlights }
    </div> }
  </Link>
}
