import { Expose, Type } from "class-transformer";
import { FileModel } from "./file.model";
import { FolderModel } from "./folder.model";

export class DocumentModel extends FileModel {
  static get pinnedDocs() {
    return JSON.parse(window.localStorage.getItem('clarity.pinnedDocs') || '[]');
  }

  @Expose()
  public folderId: string;

  public folder: FolderModel;

  @Expose()
  public pageCount: number;

  @Expose()
  public isIndexed: boolean;

  @Expose()
  @Type(() => Date)
  public indexedAt: Date;

  get isPinned() {
    return DocumentModel.pinnedDocs.includes(this.id);
  }

  set isPinned(value: boolean) {
    const { pinnedDocs } = DocumentModel;

    if (value && !pinnedDocs.includes(this.id)) {
      pinnedDocs.push(this.id);
    } else {
      const index = pinnedDocs.indexOf(this.id);
      if (index !== -1) {
        pinnedDocs.splice(index, 1);
      }
    }

    window.localStorage.setItem('clarity.pinnedDocs', JSON.stringify(pinnedDocs));
  }
}
