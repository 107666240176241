import "./index.scss";
import { Dropdown, DropdownButton } from "../Dropdown";
import clsx from "clsx";
import { useState } from "react";

interface SelectProps<T extends { disabled?: boolean } = any> {
  items: T[];
  itemLabelField?: keyof T;
  itemValueField?: keyof T;
  value?: T[keyof T];
  disabled?: boolean;
  pending?: boolean;
  name?: string;
  label?: string;
  defaultValue?: string;
  style?: React.CSSProperties;

  onChange?: (value: T[keyof T]) => void;
}

export const Select = (props: SelectProps) => {
  type T = SelectProps['items'][0];
  const [currentValue, setCurrentValue] = useState<T[keyof T] | undefined>(props.defaultValue as T[keyof T]);

  const key = props.itemValueField || 'value' as keyof T;
  const label = props.itemLabelField || 'label' as keyof T;
  const valueLabel = props.items.find(item => item[key] === (props.value || currentValue))?.[label] as string;

  const activator = <div 
    className={clsx({
      "select field": true,
      "select--disabled": props.disabled,
    })}

    style={ props.style }
  >
    { props.label && <div className="field__label" children={ props.label } /> }
    <button 
      disabled={ props.disabled }
      className={ 
        clsx({
          "select__trigger button button--gray": true,
          "button--pending": props.pending,
        }) 
      }
      type="button"
    >
      { valueLabel || 'Select' }
      <i className="icon-arrow-down-s-line" />
    </button>

    { props.name && <input 
      hidden 
      value={ currentValue as string } 
      name={ props.name }
    /> }
  </div>

  return <>

    <Dropdown 
      activator={ activator }
      dropdownClass="select__dropdown"
    >
      { props.items
        .filter(item => !item.disabled)
        .map((item, index) => (
        <DropdownButton 
          key={index} 
          children={item[label] as string}
          onClick={ () => {
            setCurrentValue(item[key] as T[keyof T]);
            props.onChange?.(item[key] as T[keyof T]);
          } }
        />
      )) }
    </Dropdown>
  </>
}
