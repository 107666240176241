import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ClarityDocsApi } from "src/api";
import { resolveRoute, useNavigate } from "src/router/routes";

import ERROR_IMAGE from "src/assets/images/close-circle.svg";
import { ArtworkLayout } from "src/components/layouts/ArtworkLayout";

const session = new ClarityDocsApi();

export const Page = () => {
  const params = useParams<{ inviteToken: string }>();
  const navigate = useNavigate();
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    session.team.getInvitation(params.inviteToken!)
      .catch(() => {
        setIsError(true);
        return null; 
      })
      .then(invitation => {
        if (!invitation) {
          return;
        }

        window.localStorage.setItem('clarity.teamInviteToken', params.inviteToken!);
        navigate({ name: 'root' });
      })

  }, [params.inviteToken, navigate]);

  if (isError) {
    return <ArtworkLayout>
      <div 
        className="flex flex--col flex--acenter flex--jcenter"
        style={{
          height: '100vh',
        }}
      >
        <div className="tx-center">
          <img src={ ERROR_IMAGE } alt="Error" width="100" />
        </div>
        <p className="txc-placeholder">
          Invitation is invalid or has already been used
        </p>

        <Link 
          className="button button--primary" 
          to={ resolveRoute({ name: 'signin' }) }
          children="Sign in"
        />
      </div>
    </ArtworkLayout>
  }

  return null;
}
