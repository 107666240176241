import { useCallback, useEffect, useRef, useState } from "react";
import { ClarityDocsApi, MessageReceiveEvent } from "..";
import { MessageModel } from "../models/message.model";
import { ClarityChatEvent, MessagePayload } from "../services/chat.service";

const session = new ClarityDocsApi();

export const useChat = (folderId: string) => {
  const page = useRef(1);
  const loading = useRef(false);

  const [messages, setMessages] = useState<MessageModel[]>([]);
  const [isRequestingMessages, setIsRequestingMessages] = useState(true);
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const [isAllMessagesLoaded, setIsAllMessageLoaded] = useState(false);

  const sendMessage = useCallback((payload: MessagePayload) => {
    setIsSendingMessage(true);
    session.chat.sendMessage(payload, folderId)
      .finally(() => setIsSendingMessage(false));
  }, [folderId]);

  const abortMessage = useCallback(() => {
    session.chat.abortMessage();
    setIsSendingMessage(false);
  }, []);

  const loadMoreMessages = useCallback(() => {
    if (loading.current || isAllMessagesLoaded) {
      return;
    }

    page.current += 1;
    loading.current = true;
    setIsRequestingMessages(true);

    session.chat.getMessages(folderId, page.current, 10)
      .then((list) => {
        if (!list?.length) {
          setIsAllMessageLoaded(true);
          return;
        }
        setMessages(oldList => [...oldList, ...(list || [])])
      })
      .finally(() => {
        setIsRequestingMessages(false);
        loading.current = false;
      });

  }, [folderId, isAllMessagesLoaded]);

  // NOTE: Reinitialize messages when folderId changes
  useEffect(() => {
    page.current = 1;
    loading.current = true;

    setMessages([]);

    session.chat.getMessages(folderId, 1, 10)
      .then(m => setMessages(m || []))
      .finally(() => {
        setIsRequestingMessages(false);
        loading.current = false;
      });
  }, [folderId]);

  // NOTE: Listen for new messages
  useEffect(() => {
    const onMessageReceived = ({ folderId: forFolderId, message }: MessageReceiveEvent) => {
      if (forFolderId !== folderId) {
        return;
      }

      setMessages(oldMessages => {
        if (oldMessages.some(m => m.id === message.id)) {
          return oldMessages;
        }

        return [message, ...oldMessages];
      });
    };

    const onMessageUpdated = ({ folderId: forFolderId, message }: MessageReceiveEvent) => {
      if (forFolderId !== folderId) {
        return;
      }

      setMessages(oldMessages => {
        return oldMessages.map(m => {
          return m.id === message.id ? message : m;
        });
      });
    };

    const unsubReceived = session.on(ClarityChatEvent.MESSAGE_RECEIVED, onMessageReceived);
    const unsubUpdated = session.on(ClarityChatEvent.MESSAGE_UPDATED, onMessageUpdated);

    return () => {
      unsubReceived();
      unsubUpdated();
    };
  }, [folderId]);

  return {
    messages,
    sendMessage,
    abortMessage,
    loadMoreMessages,
    isRequestingMessages,
    isSendingMessage,
  }
}
