import clsx from "clsx";
import { Dropdown } from ".";

export interface DropdownButtonProps {
  children?: string | React.ReactNode | React.ReactNode[];
  icon?: string | React.ReactElement;
  label?: string;
  submenu?: React.ReactNode;
  disabled?: boolean;

  onClick?: () => void;
}

export const DropdownButton = (props: DropdownButtonProps) => {
  const iconIsComponent = typeof props.icon === 'object';

  const button = <button
    className={clsx({
      'dropdown__button': true,
      'dropdown__button--submenu': props.submenu,
    })}
    onClick={ props.onClick }
    disabled={ props.disabled }
  >
    { !iconIsComponent && props.icon && <i className={ `icon-${props.icon}` }></i> }
    { iconIsComponent && props.icon }

    <div className="dropdown__button-label">
      { props.children }
      { props.label }
    </div>

    { props.submenu && <i className="dropdown__submenu-icon icon-slide-right"></i> }
  </button>

  if (props.submenu) {
    return <Dropdown
      activator={ button }
      vside="top"
      hside="right"
      alignAxis="x"
      isSubmenu
    >
      { props.submenu }
    </Dropdown>;
  }


  return button;
}
