import "./index.scss";
import { Popup } from "src/components/ui";
import { Tabs } from "src/components/ui/Tabs";
import { GeneralTab } from "./GeneralTab";
import { ManageMembersTab } from "./ManageMembersTab";
import { SubscriptionTab } from "./SubscriptionTab";
import { useEffect, useState } from "react";

interface TeamSettingsPopupProps {
  statePair: [boolean | string, React.Dispatch<React.SetStateAction<boolean>>];
}

export interface SettingsTabProps {
  requestClose: () => void;
}

export const TeamSettingsPopup = (props: TeamSettingsPopupProps) => {
  const tabsStatePair = useState(0);

  // NOTE Autoswitch to the members tab
  useEffect(() => {
    if (!props.statePair[0]) {
      tabsStatePair[1](0);
    }

    if (props.statePair[0] === 'members') {
      tabsStatePair[1](1);
      props.statePair[1](true);
    }
  }, [props.statePair, tabsStatePair]);

  return <Popup 
    width={ 820 }
    statePair={ props.statePair }
    title = "Team settings"
    onClose={ () => tabsStatePair[1](0) }
  >
    <Tabs
      tabs={['General', 'Manage members', 'Subscription']}
      className="team-management-tabs"
      statePair={ tabsStatePair }
    >
      <GeneralTab requestClose={ () => props.statePair[1](false) } />
      <ManageMembersTab requestClose={ () => props.statePair[1](false) } />
      <SubscriptionTab requestClose={ () => props.statePair[1](false) } />
    </Tabs>
  </Popup>
}
