import { ArtworkLayout } from "src/components/layouts/ArtworkLayout"

import IMAGE_ARTWORK from 'src/assets/images/artwork.jpg'
import SUCCESS_IMAGE from 'src/assets/images/tick-circle.svg'
import ERROR_IMAGE from 'src/assets/images/close-circle.svg'
import { useEffect, useState } from "react"
import { ClarityDocsApi } from "src/api"
import { Link, useParams } from "react-router-dom"
import { resolveRoute } from "src/router/routes"

const session = new ClarityDocsApi();

const CONFIRMED_MESSAGE = <>
  <div className="tx-center">
    <img width="100" src={ SUCCESS_IMAGE } alt="Success" />
  </div>

  <h1 className="tx-section-heading">
    Account confirmed
  </h1>

  <p>
    Your account has been confirmed, you can now log in to the application
  </p>

  <div className="tx-center">
    <Link
      className="button button--primary"
      children="Go to login" 
      to={ resolveRoute({ name: "signin" }) }
    />
  </div>
</>

const ERROR_MESSAGE = <>
  <div className="tx-center">
    <img width="100" src={ ERROR_IMAGE } alt="Success" />
  </div>

  <h1 className="tx-section-heading">
    Invalid confirmation link
  </h1>

  <p className="txc-placeholder">
    Be sure you entered this page by clicking the confirmation link sent to your email
  </p>
  <div className="tx-center">
    <Link
      className="button button--primary"
      children="Go to login" 
      to={ resolveRoute({ name: "signin" }) }
    />
  </div>
</>

export const Page = () => {
  const params = useParams<{ token: string }>();
  const [status, setStatus] = useState<boolean | null>(null);

  useEffect(() => {
    if (!params.token || status) return;
    session.confirmUser(params.token)
      .then(setStatus);
  }, [params, status]);

  return <ArtworkLayout imageSrc={ IMAGE_ARTWORK }>
    <div className="flex flex--col flex--gap30 tx-center">
      { 
        status === null
        ? <div className="spinner" /> 
        : status ? CONFIRMED_MESSAGE : ERROR_MESSAGE
      }
    </div>
  </ArtworkLayout>
}
