import './index.scss';

import { ReactElement } from "react";

import LOGO_COMPACT from 'src/assets/images/logo-compact.svg';
import clsx from 'clsx';

interface ArtworkLayoutProps {
  children: (ReactElement | null)[] | ReactElement;
  imageSrc?: string;
}

export const ArtworkLayout = (props: ArtworkLayoutProps) => {
  return <main className={clsx({
    "artwork-layout": true,
    "artwork-layout--with-image": !!props.imageSrc,
  }) }>
    { props.imageSrc && <img 
      src={ props.imageSrc }
      className="artwork-layout__artwork" 
      alt="Artwork"
    /> }

    <div className="artwork-layout__content">
      <div className="artwork-layout__logo">
        <img
          src={ LOGO_COMPACT }
          alt="Clarity Docs"
        />
      </div>
      <div className="artwork-layout__container">
        { props.children }
      </div>
    </div>
  </main>
}
